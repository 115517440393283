/* eslint-disable */
import React from 'react';
import { List, Typography } from 'antd';

// Define the colors for different statuses
const statusColors: { [key: string]: string } = {
  draft: '#602A75', // Purple
  submitted: '#CCAA00', // Yellow
  approved: '#007227', // Green
  rejected: '#810220', // Red
  'rejected by finance': '#D36500', // Orange
};

interface PromotionData {
  status: string;
  count?: number; // Mark as optional to handle missing data
}

const UpcomingPromotion: React.FC<{ promotionData: PromotionData[] }> = ({ promotionData }) => {
  return (
    <List
  itemLayout="horizontal"
  dataSource={promotionData}
  renderItem={(item) => (
    <List.Item actions={[<a href="#" style={{ fontSize: '12px' }}>View Detail</a>]}>
      <List.Item.Meta
        avatar={
          <span
            style={{
              backgroundColor: statusColors[item.status.toLowerCase()] || '#000',
              width: 10,
              height: 10, // Make width and height equal for perfect round shape
              display: 'inline-block',
              borderRadius: '50%', // Full round shape
              marginRight: 10,
            }}
          />
        }
        title={<span style={{ fontSize: '12px', fontWeight: 'normal' }}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>}
        />
      <Typography.Text>
        <span style={{ fontSize: '12px' }}>{item.count ?? 0}</span> {/* Default to 0 if count is missing */}
      </Typography.Text>
    </List.Item>
  )}
  style={{ padding: '0px', marginLeft: '16px', marginRight: '16px' }} // Add margin here
/>

  );
};

export default UpcomingPromotion;
