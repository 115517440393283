/* eslint-disable */
import React from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Component definition
const RadarChartComponent = () => {
  // Sample data structure
  const sampleData = [
    { category: 'Coca Cola', actual: 80, planned: 70 },
    { category: 'Sprite', actual: 60, planned: 65 },
    { category: 'Fanta', actual: 30, planned: 40 },
    { category: 'Miranda', actual: 40, planned: 50 },
    { category: 'Next Cola', actual: 60, planned: 55 },
    { category: 'Diet Coke', actual: 20, planned: 25 },
    { category: 'Diet 7up', actual: 45, planned: 35 },
    { category: 'Redbull', actual: 70, planned: 60 },
    { category: 'Sprite Mint', actual: 50, planned: 45 },
  ];

  // Use provided data or fallback to sample data if data is not provided
  const chartData = sampleData;

  return (
    <ResponsiveContainer width="100%" height={240}>
      <RadarChart data={chartData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="category" />
        <PolarRadiusAxis angle={30} domain={[0, 80]} />
        <Radar
          name="Actual"
          dataKey="actual"
          stroke="#3268C7"
          fill="#3268C7"
          fillOpacity={0.6}
        />
        <Radar
          name="Planned"
          dataKey="planned"
          stroke="#DDE4F0"
          fill="#DDE4F0"
          fillOpacity={0.6}
        />
        <Legend verticalAlign="bottom" align="center" />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChartComponent;
