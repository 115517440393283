/* eslint-disable */

import { ReactElement, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Statistic,
  Switch,
  DatePicker,
  Select,
  TabsProps,
  Input,
  Button,
  Tabs,
  Spin,
  Dropdown,
  Space,
  MenuProps,
  Menu
} from 'antd';
import { PlusOutlined, CaretUpOutlined,CaretDownOutlined , DownOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import TopPerformingPromotions from './topPerformingPromotions';

import ProductPerformance from './productPerformance';
import { Line, Column } from '@ant-design/plots';
import DashboardLayout from './calendar';
import CategoryBrandAnalysis from './categoryBrandAnalysis';
import RetailerPerformance from './retailerPerformance';
import FinancialAnalysis from './financialAnalysis';
import ChannelPerformance from './channelPerformance';
import SupplyChainImpact from './supplyChainImpect';
import SalesAndRevenueTrends from './salesAndRevenueTrend';
import OverAllPromotion from './overAllPromotion';
import UpcomingPromotion from './upcomingPromotion'
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

import Filters from './filterBy';
import {
  getDashboardMetrics,
  getDashboardMetricsCategory,
  // getOverallPromotion 
} from './ducks2/slice';

import './antdCustom.css'
const { Option } = Select;  // Ensure Option is declared here

const OverView = (props: any): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    dashboardView: { dashboardMetrics, bargraph },
  } = useAppSelector((state: any) => state);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    if (Object.keys(dashboardMetrics).length === 0) {
      dispatch(
        getDashboardMetrics({
          startDate: '2024-01-02',
          endDate: '2024-12-30',
        }),
        
      ).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [dashboardMetrics, dispatch]);

  useEffect(() => {
    dispatch(getDashboardMetricsCategory(
      {
        startDate: '2024-01-01',
        endDate: '2024-12-31',
      }
    ));
  }, []);
  const [selectedType, setSelectedType] = useState<'sales' | 'revenue'>('sales'); // Default to 'sales'
  const [showWorst, setShowWorst] = useState<boolean>(false); // Toggle for top/worst performing promotions
  const [showWorstProduct, setShowWorstProduct] = useState<boolean>(false); // Toggle for best/worst performing products
  const [selectedSort, setSelectedSort] = useState<string>('roi'); // Default sort by 'roi'

  const [selectedYearRange, setSelectedYearRange] = useState("FY 2023-2024");
  const [startYear, setStartYear] = useState("2024");
  const [endYear, setEndYear] = useState("2024");
  const [sortByProduct, setSortByProduct] = useState('volume'); // Default sort metric for products

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedSort(e.key);
  };
  const handleMenuClickSalesAndRev: MenuProps['onClick'] = (e) => {
    if (e.key === '0') {
      setSelectedType('sales');
    } else if (e.key === '1') {
      setSelectedType('revenue');
    }
  };
  const handleDateChangeOverAllPromotion = (dates:any) => {
    if (dates && dates.length === 2) {
      const fromYear = dayjs(dates[0]).format("YYYY");
      const toYear = dayjs(dates[1]).format("YYYY");
      setSelectedYearRange(`FY ${fromYear}-${toYear}`);
    }
  };

  const handleYearChange = (value: any, type: any) => {
    if (type === "start") {
      setStartYear(value);
    } else {
      setEndYear(value);
    }
    sendDateRange(value, type === "start" ? endYear : startYear, type);
  };
  
  const sendDateRange = (start: any, end: any, type: any) => {
    let startDate;
    let endDate;
  
      // Set start date to June 1 of the previous year and end date to June 30 of the selected year
      startDate = `${parseInt(start)}-01-01`;
      console.log('startDate: ', startDate);
      endDate = `${start}-12-31`;
      console.log('endDate: ', endDate);
   
  
    dispatch(getDashboardMetricsCategory({ startDate, endDate }));
  };
  

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
      options.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
    }
  
    return options;
  };
  

  const generateYearRangeOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
      options.push(<Select.Option key={`${i}-${i + 1}`} value={`${i}-${i + 1}`}>{`${i} - ${i + 1}`}</Select.Option>);
    }
  
    return options;
  };

  const itemsForTopPromotion: MenuProps['items'] = [
    { label: 'By ROI', key: 'roi' },
    { label: 'By Volume', key: 'volume' },
    { label: 'By Cost', key: 'cost' },
  ];

  const itemsForSalesAndPromo: MenuProps['items'] = [
    {
      label: <a>Sales</a>,
      key: '0',
    },
    {
      label: <a>Revenue</a>,
      key: '1',
    },
  ];
  
  
  const handleProductMetricChange: MenuProps['onClick'] = (e) => {
    setSortByProduct(e.key); // Update metric based on selection
  };
  
  const itemsForProductPerformance: MenuProps['items'] = [
    { label: 'By Volume', key: 'volume' },
    { label: 'By Cost', key: 'cost' },
    { label: 'By ROI', key: 'roi' },
  ];  return (
    <>
      <div className="dashboard-view-wrapper" style={{ padding: '20px' }}>
        <Row gutter={[12, 12]}>
          {/* Second Column - Overall ROI */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
            <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
          Total Revenue
        </span>
      }
      value={dashboardMetrics?.totalrevenue?.totalRevenue || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
      prefix="$"
    />
  </Col>
  <Col>
    <Statistic
      value={dashboardMetrics?.totalrevenue?.totalRevenueChange || 0}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.totalrevenue?.totalRevenueChange >= 0 ? '#52C41A' : '#F5222D',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={dashboardMetrics?.totalrevenue?.totalRevenueChange >= 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
      vs last period
    </span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Second Column - Overall ROI */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
   <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={<span style={{ fontSize: '14px', color: '#5C5C5C' }}>Overall ROI</span>}
      value={dashboardMetrics?.overAllRoi?.totalROI || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
      suffix="%"
    />
  </Col>
  <Col>
    <Statistic
      value={dashboardMetrics?.overAllRoi?.totalROI || 0}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.overAllRoi?.totalROIChange > 0 ? '#52C41A' : '#FF4D4F',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={dashboardMetrics?.overAllRoi?.totalROIChange > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>vs last period</span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Third Column - Active Promotion */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
        <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
          Active Promotions
        </span>
      }
      value={dashboardMetrics?.activePromotion?.activePromotions || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
    />
  </Col>
  <Col>
    <Statistic
      value={Math.abs(dashboardMetrics?.activePromotion?.totalChangeInActive || 0)}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.activePromotion?.totalChangeInActive >= 0 ? '#52C41A' : '#FF4D4F',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={
        dashboardMetrics?.activePromotion?.totalChangeInActive >= 0 
          ? <CaretUpOutlined /> 
          : <CaretDownOutlined />
      }
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
      vs last period
    </span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Fourth Column - Market Share */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
              <Row justify="space-between" align="middle">
                <Col>
                  <Statistic
                    title={
                      <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                        Market Share
                      </span>
                    }
                    value={10}
                    precision={0}
                    valueStyle={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: '#1E1E1E',
                    }}
                    suffix="%"
                  />
                </Col>
                <Col>
                  <Statistic
                    value={8.5}
                    precision={1}
                    valueStyle={{
                      color: '#52C41A',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    prefix={<CaretUpOutlined />}
                    suffix="%"
                  />
                  <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                    vs last 7 days
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* First Column - Total Revenue */}
          <Col xs={24} sm={12} lg={6}>
  <Card
    className="custom-card"
    style={{
      borderRadius: '0px',
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      height: '100%', // Dynamic height
    }}
  >
    <Row justify="space-between" align="middle">
      <Col>
        <Statistic
          title={
            <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
              Planned Revenue
            </span>
          }
          value={dashboardMetrics?.actualvsPlannedRevenue?.plannedRevenue?.totalPlannedRevenue || 0}
          precision={0}
          valueStyle={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#1E1E1E',
          }}
          prefix="$"
        />
      </Col>
      <Col>
        <Statistic
          value={dashboardMetrics?.actualvsPlannedRevenue?.plannedRevenue?.plannedRevenueChange || 0}
          precision={1}
          valueStyle={{
            color: dashboardMetrics?.actualvsPlannedRevenue?.plannedRevenue?.plannedRevenueChange >= 0 
              ? '#52C41A' 
              : '#FF4D4F', // Green for positive, red for negative
            fontSize: '14px',
            fontWeight: 'bold',
          }}
          prefix={dashboardMetrics?.actualvsPlannedRevenue?.plannedRevenue?.plannedRevenueChange >= 0 
            ? <CaretUpOutlined /> 
            : <CaretDownOutlined />} // Arrow icon based on the value
          suffix="%"
        />
        <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
          vs last period
        </span>
      </Col>
    </Row>
  </Card>
</Col>

          {/* Second Column - Overall ROI */}
          <Col xs={24} sm={12} lg={6}>
  <Card
    className="custom-card"
    style={{
      borderRadius: '0px',
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      height: '100%',
    }}
  >
    <Row justify="space-between" align="middle">
      <Col>
        <Statistic
          title={
            <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
              Planned ROI
            </span>
          }
          value={dashboardMetrics?.targetRoi?.totalROI || 0}
          precision={0}
          valueStyle={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#1E1E1E',
          }}
          suffix="%"
          />
      </Col>
      <Col>
        <Statistic
          value={dashboardMetrics?.targetRoi?.totalROIChange || 0}
          precision={1}
          valueStyle={{
            color: dashboardMetrics?.targetRoi?.totalROIChange >= 0 ? '#52C41A' : '#FF4D4F', // Green for positive, red for negative
            fontSize: '14px',
            fontWeight: 'bold',
          }}
          prefix={dashboardMetrics?.targetRoi?.totalROIChange >= 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
          suffix="%"
        />
        <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
          vs last period
        </span>
      </Col>
    </Row>
  </Card>
</Col>

          {/* Third Column - Active Promotion */}
          <Col xs={24} sm={12} lg={6}>
  <Card
    className="custom-card"
    style={{
      borderRadius: '0px',
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      height: '100%', // Dynamic height
    }}
  >
   <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
          Total Budget
        </span>
      }
      value={dashboardMetrics?.totalBudget?.totalBudget || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
      prefix="$"
    />
  </Col>
  <Col>
    <Statistic
      value={dashboardMetrics?.totalBudget?.totalBudgetChange || 0}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.totalBudget?.totalBudgetChange >= 0
          ? '#52C41A' // Green for positive change
          : '#FF4D4F', // Red for negative change
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={dashboardMetrics?.totalBudget?.totalBudgetChange >= 0 
        ? <CaretUpOutlined /> 
        : <CaretDownOutlined />}
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
      vs last period
    </span>
  </Col>
</Row>

  </Card>
</Col>

          {/* Fourth Column - Market Share */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              bordered={true}
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
                backgroundColor: '#E6F7FF', // Light blue background
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px dashed #1890ff',
              }}
            >
              <Button
                type="text"
                icon={<PlusOutlined />}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                Add Custom
              </Button>
            </Card>
          </Col>
        </Row>

        {/* Second Row - Sales and Revenue Trend */}
        <Row gutter={[16, 16]} style={{ marginTop: '18px' }}>
        <Col xs={24} sm={12} lg={12}>
      
        <Card
  className="custom-card"
  bordered={false}
  title={
    <Row justify="space-between" align="middle">
      <Col>
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>Overall Promotions</span>
      </Col>
      <Col>
        <Button
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            padding: '4px 8px',
            display: 'flex',
            alignItems: 'center',
            color: '#333',
            fontSize: '14px',
          }}
        >
          <Select
            value={startYear}
            onChange={(value) => {
              handleYearChange(value, "start");
              handleYearChange(value, "end");
            }}
            bordered={false}
            style={{ width: 80, color: '#3369C8' }}
          >
            {generateYearOptions()} {/* Function to generate options for each year */}
          </Select>
        </Button>
      </Col>
    </Row>
  }
  style={{
    height: '351px',
    border: 'none',
    borderBottom: 'none',
  }}
>
  <OverAllPromotion
    overviewData={{
      totalPromotions: bargraph.totalPromotions?.totalPromotions || '0',
      activePromotions: bargraph.activePromotions?.activePromotions || '0',
      completedPromotions: bargraph.completedPromotions?.completedPromotions || '0',
      totalROI: bargraph.totalROI?.totalROI|| 0 ,
      productsPromoted: bargraph.productsPromoted?.productsPromoted || '0',
      upcomingPromotions: bargraph.upcomingPromotions?.upcomingPromotions || '0',
      getTotalSpend: bargraph.getTotalSpend?.totalSpend || '0',
    }}
    startDate={`${startYear}-01-01`} // Automatically set start date to January 1 of the selected year
    endDate={`${startYear}-12-31`} // Automatically set end date to December 31 of the selected year
  />
</Card>


</Col>

  
          <Col xs={24} sm={12} lg={6}>
          <Card
            className="custom-card"

              title={
                <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
               Upcoming Promotions
             </span>
              }
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
                padding: '0px',
                marginTop: '0px',
              }}
              bodyStyle={{
                padding: '0px', // Remove padding inside the card body
              }}
            >
              <UpcomingPromotion promotionData={dashboardMetrics.getPromotionCountsByStatus} />

            </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card
            className="custom-card"

              title={
                <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
               Financial Analysis
             </span>
              }
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
                // display: 'flex',              // Use flexbox layout
                justifyContent: 'center',      // Horizontally center the content
                alignItems: 'center',          // Vertically center the content
                flexDirection: 'column',
              }}
            >
              <FinancialAnalysis data={dashboardMetrics.financialAnalysis || []} /> 
              </Card>
        </Col>
        </Row>
                {/* third Row - Sales and Revenue Trend */}

        <Row gutter={[16, 16]} style={{ marginTop: '18px' }}>
        <Col span={12}>
  <Card
    className="custom-card"

    title={
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col>
          <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
            Sale and Revenue Trend
          </span>
        </Col>
        <Col>
          <Dropdown
            menu={{
              items: itemsForSalesAndPromo,
              onClick: handleMenuClickSalesAndRev,
            }}
            trigger={['click']}
          >
           <Space
  onClick={(e) => e.preventDefault()}
  style={{
    fontSize: '14px',
    color: '#333',
    border: '1px solid #DDE4F0',
    borderRadius: '4px',
    padding: '4px 12px',
    backgroundColor: '#F9FAFB',
    cursor: 'pointer',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    width: '133px',
    height: '33px',
    fontWeight: 'normal',
  }}
>
  {selectedType === 'sales' ? 'Sales Tons' : 'Revenue $'} <DownOutlined />
</Space>

          </Dropdown>
        </Col>
      </Row>
    }
    style={{
      borderRadius: '0px',
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      height: '351px',
    }}
  >
    <SalesAndRevenueTrends
      data={dashboardMetrics.salesAndRevenueTrends || []}
      type={selectedType}
    />
  </Card>
</Col>

    <Col span={12}>
    <Card
  className="custom-card"
  title={
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {/* Dynamic Title */}
      <div style={{ fontSize: '14px', color: '#5C5C5C' }}>
        {showWorst ? 'Worst Performing Promotions' : 'Top Performing Promotions'}
      </div>

      {/* Right-aligned Dropdown Selector and Switch */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown menu={{ items: itemsForTopPromotion, onClick: handleMenuClick }} trigger={['click']}>
          <Space
            style={{
              fontSize: '14px',
              color: '#333',
              border: '1px solid #DDE4F0',
              borderRadius: '4px',
              padding: '4px 12px',
              backgroundColor: '#F9FAFB',
              cursor: 'pointer',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              width: '133px',
              height: '33px',
              fontWeight: 'normal',
            }}
          >
            {selectedSort === 'roi' ? 'By ROI' : selectedSort === 'volume' ? 'By Volume' : 'By Cost'}
            <DownOutlined />
          </Space>
        </Dropdown>

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <span style={{ fontSize: '14px', color: '#5C5C5C', marginRight: '8px' }}>Worst Promotions</span>
          <Switch
            defaultChecked={false}
            onChange={(checked) => setShowWorst(checked)}
            checked={showWorst}
          />
        </div>
      </div>
    </div>
  }
  style={{ borderRadius: '0px', height: '351px' }}
>
  <TopPerformingPromotions
    data={showWorst ? dashboardMetrics?.topPerformingPromotion?.worstPerforming || [] : dashboardMetrics?.topPerformingPromotion?.topPerforming || []}
    sortBy={selectedSort}
  />
</Card>

</Col>




        </Row>

        {/* Other cards */}
        <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
        <Col xs={0} sm={12} lg={6}>
  <Card
    className="custom-card"
    title={
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left-aligned Title with Unit */}
        <span style={{ fontSize: '14px', color: '#5C5C5C', fontWeight: 'bold' }}>
          Product Performance ({sortByProduct === 'volume' ? 'Tons' : sortByProduct === 'cost' ? '$' : '%'})
        </span>

        {/* Right-aligned Dropdown Selector */}
        <Dropdown
          overlay={
            <Menu
              items={itemsForProductPerformance}
              onClick={handleProductMetricChange}
            />
          }
          trigger={['click']}
        >
          <Space
            style={{
              fontSize: '14px',
              color: '#333',
              border: '1px solid #DDE4F0', // Light border color
              borderRadius: '4px',
              padding: '4px 12px',
              backgroundColor: '#F9FAFB',
              cursor: 'pointer',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              width: '103px', // Set width
              height: '33px', // Set height
              fontWeight: 'normal', // Remove bold font
            }}
          >
            {sortByProduct === 'volume' ? 'Volume Tons' : sortByProduct === 'cost' ? 'Cost $' : 'ROI %'}
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
    }
    bodyStyle={{ padding: '0' }}
    style={{
      borderRadius: '0px',
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      height: '312px',
      padding: 0,
    }}
  >
    <ProductPerformance
      data={showWorstProduct ? dashboardMetrics?.productPerformance?.worstPerforming || [] : dashboardMetrics?.productPerformance?.topPerforming || []}
      sortBy={sortByProduct}
    />
  </Card>
</Col>



          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              title= { <span style={{ fontSize: '14px' }}>Category Analysis </span> }
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '312px',
                padding: 0

              }}
            >
              <CategoryBrandAnalysis data={dashboardMetrics.categoryBrandAnalysis || []} />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Card
              className="custom-card"

             title= { <span style={{ fontSize: '14px' }}>Retailer Performance</span> }
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '312px',
                padding: 0

              }}
            >
              <RetailerPerformance data={dashboardMetrics.retailerPerformance || []} />
            </Card>
          </Col>
       
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
          <Col span={12}>
            <Card
              className="custom-card"

               title={<span style={{ fontSize: '14px' }}>Channel Performance</span>}
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '350px',
              }}
            >
              <ChannelPerformance data={dashboardMetrics.channelPerformance || []} />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="custom-card"

               title={<span style={{ fontSize: '14px' }}>Supply Chain Impact</span>}
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '350px',
              }}
            >
              <SupplyChainImpact data={dashboardMetrics.supplyChainImpact || []} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OverView;
