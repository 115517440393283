/* eslint-disable */

import request from 'utils/axios';
import { httpVerbs } from 'configs';
import { IDashboardMetricsReponse } from './types';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDashboardMetricsApi = async ({
  startDate,
  endDate,
}: { startDate?: string; endDate?: string } = {}): Promise<IDashboardMetricsReponse> => {
  // Construct the query string only if startDate or endDate is provided
  const queryParams = new URLSearchParams();
  
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);

  // Construct the final URL with query parameters (if any)
  const url = queryParams.toString() 
    ? `${BASE_URL}/dashboard?${queryParams.toString()}` 
    : `${BASE_URL}/dashboard`;

  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };

  const response = await request(params);
  return response;
};



// services.ts

export const getDashboardMetricsCategoryApi = async ({
  startDate,
  endDate,
}: { startDate?: string; endDate?: string } = {}): Promise<IDashboardMetricsReponse> => {
  console.log('startDate: ', startDate);
  console.log('endDate: ', endDate);
  const queryParams = new URLSearchParams();
  console.log("=====================================");
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);

  const url = queryParams.toString()
  ? `${BASE_URL}/dashboard/overall-promotion?${queryParams.toString()}`
  : `${BASE_URL}/dashboard/overall-promotion`;
  
  console.log('url: ', url);
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };

  const response = await request(params);
  return response;
};


  // services.ts
export const getOverallPromotionApi = async ({
  startDate,
  endDate,
}: { startDate?: string; endDate?: string } = {}): Promise<any> => {
  const queryParams = new URLSearchParams();
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);

  const url = queryParams.toString()
    ? `${BASE_URL}/dashboard/overall-promotion?${queryParams.toString()}`
    : `${BASE_URL}/dashboard/overall-promotion`;

  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };

  const response = await request(params);
  return response;
};

