/* eslint-disable */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const dataa = [
  { name: 'Men Shoes', Category: 80, Brand: 60 },
  { name: 'Grocery', Category: 50, Brand: 90 },
  { name: 'Cosmetics', Category: 100, Brand: 60 },
  { name: 'Women Sandals', Category: 80, Brand: 50 },
  { name: 'Perfumes', Category: 60, Brand: 70 },
];

const SalesTrendByCategoryBrand: React.FC<{ data: any }> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={dataa}
        margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis />
        <Tooltip />
        <Legend
          align="center"
          verticalAlign="bottom"
          iconType="circle"
          wrapperStyle={{ paddingTop: 10 }}
        />
        <Bar dataKey="Category" fill="#3268C7" name="Category" />
        <Bar dataKey="Brand" fill="#DDE4F0" name="Brand" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SalesTrendByCategoryBrand;
