/* eslint-disable */

import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const SupplyChainImpact: React.FC<{ data: any }> = ({ data }) => {
  const unit = "Tons"; // Define the unit here as needed (e.g., "Tons", "$", or "%")

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        
        <XAxis dataKey="store" axisLine={false} tickLine={false} /> {/* Remove X-axis line */}
        
        <YAxis
          label={{
            value: `Value (${unit})`,
            angle: -90,
            position: 'insideLeft',
            style: { fontSize: 12, fill: "#5C5C5C" }
          }}
          axisLine={false} 
          tickLine={false}
        /> {/* Remove Y-axis line */}
        
        <Tooltip formatter={(value) => `${value} ${unit}`} />
        
        <Legend
          verticalAlign="bottom"
          formatter={(value) => value.charAt(0).toUpperCase() + value.slice(1)} // Capitalize legend entries
        />
        
        <Bar dataKey="demand" name="Demand" fill="#3268C7" radius={[0, 0, 0, 0]} />
        <Bar dataKey="supply" name="Supply" fill="#B9C7E3" radius={[0, 0, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SupplyChainImpact;
