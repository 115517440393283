/* eslint-disable */

import React from 'react';
import { Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

// Sample data for the table
const data = [
  {
    key: '1',
    category: 'Grocery',
    august: '$100,000.87',
    september: '$103,200.87',
    october: '$115,034.87',
    trend: 'up',
  },
  {
    key: '2',
    category: 'Drinks',
    august: '$100,000.87',
    september: '$103,200.87',
    october: '$115,034.87',
    trend: 'up',
  },
  {
    key: '3',
    category: 'Clothing',
    august: '$100,000.87',
    september: '$99,200.87',
    october: '$115,034.87',
    trend: 'down',
  },
  {
    key: '4',
    category: 'Chips',
    august: '$100,000.87',
    september: '$99,200.87',
    october: '$115,034.87',
    trend: 'down',
  },
];

// Define columns for the table
const columns = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (value: any) => <div style={{ padding: '1px 4px', lineHeight: '1.0' }}>{value}</div>, // Further reduced padding and line-height
  },
  {
    title: 'August',
    dataIndex: 'august',
    key: 'august',
    render: (value: any) => (
      <div style={{ padding: '1px 4px', lineHeight: '1.0' }}>
        {value} <ArrowUpOutlined style={{ color: 'green' }} />
      </div>
    ), // Reduced padding and line-height
  },
  {
    title: 'September',
    dataIndex: 'september',
    key: 'september',
    render: (value: any, record: any) => (
      <div style={{ padding: '1px 4px', lineHeight: '1.0' }}>
        {value}{' '}
        {record.trend === 'up' ? (
          <ArrowUpOutlined style={{ color: 'green' }} />
        ) : (
          <ArrowDownOutlined style={{ color: 'red' }} />
        )}
      </div>
    ), // Reduced padding and line-height
  },
  {
    title: 'October',
    dataIndex: 'october',
    key: 'october',
    render: (value: any) => (
      <div style={{ padding: '1px 4px', lineHeight: '1.0' }}>
        {value} <ArrowUpOutlined style={{ color: 'green' }} />
      </div>
    ), // Reduced padding and line-height
  },
];

const CategoryBrandPerformance: React.FC = () => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      rowClassName={() => 'reduced-row-height'} // Apply custom class to adjust row height
      style={{
        lineHeight: '1.0', // Reduce the line height for the table as a whole
        padding: '0px', // Set padding to 0
      }}
    />
  );
};

export default CategoryBrandPerformance;
