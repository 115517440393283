/* eslint-disable */

import { ReactElement, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Statistic,
  Switch,
  DatePicker,
  Select,
  TabsProps,
  Input,
  Button,
  Tabs,
  Spin,
  Dropdown,
  Space,
  MenuProps,
} from 'antd';
import { PlusOutlined, CaretUpOutlined, DownOutlined } from '@ant-design/icons';
// import type { MenuProps } from 'antd';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import OverView from './overView';
import Channel from './Channel/index';
import Filters from './filterBy';
import CategoryBrnads from './categoryAndBrands';
import {
  getDashboardMetrics,
  getDashboardMetricsCategory,
} from './ducks2/slice';

const DashboardView2 = (): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    dashboardView: { dashboardMetrics, bargraph },
  } = useAppSelector((state: any) => state);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });

  // Function to handle date changes from the Filters component
  // Function to handle date changes from the Filters component

  useEffect(() => {
    // If dashboardMetrics is empty, dispatch the action to get data
    if (Object.keys(dashboardMetrics).length === 0) {
      dispatch(
        getDashboardMetrics({
          startDate: '2024-01-01', // Start date as 1 Jan 2024
          endDate: '2024-06-30', // End date as 30 Oct 2024
        }),
      ).then(() => {
        // Set loading to false after the data has been fetched
        setLoading(false);
      });
    } else {
      setLoading(false); // If the data is already available, no need to load
    }
  }, [dashboardMetrics, dispatch]);

 
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Overview',
      children: (
        <>
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <OverView />
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Category / Brand',
      children: (
        <>
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <CategoryBrnads />
          )}
        </>
      ),
    },
    {
      key: '3',
      label: (
        <span style={{ color: '#B0B0B0', cursor: 'not-allowed' }}>Channel</span> // Light color and disabled cursor
      ),
      children: (
        <div style={{ textAlign: 'center', padding: '20px', color: '#B0B0B0' }}>
          This tab is inactive.
        </div>
      ),
      disabled: true, // Make the tab inactive
    },
    {
      key: '4',
      label: (
        <span style={{ color: '#B0B0B0', cursor: 'not-allowed' }}>Supply Chain</span> // Light color and disabled cursor
      ),
      children: (
        <div style={{ textAlign: 'center', padding: '20px', color: '#B0B0B0' }}>
          This tab is inactive.
        </div>
      ),
      disabled: true, // Make the tab inactive
    },
  ];
  
  const onChange = (key: string) => {
    // console.log(key);
  };
 

  return(
    <div id="dashboard-content">
         <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;

  </div>
  )
};

export default DashboardView2;
//FinancialAnalysis
