/* eslint-disable */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface Props {
  data: any[]; // Product performance data
  sortBy: string;
}

const ProductPerformance: React.FC<Props> = ({ data, sortBy }) => {
  // Map data for chart based on selected metric
  const dataForBarChart = data.map((item: any, index: any) => {
    const actualValue = sortBy === 'volume' ? item.total_volume : sortBy === 'cost' ? item.total_cost : item.roi;
    const plannedValue = sortBy === 'volume' ? item.planned_volume : sortBy === 'cost' ? item.planned_cost : item.planned_roi;

    return {
      name: item.product_name || `Product ${index + 1}`,
      Actual: actualValue,
      Planned: plannedValue,
    };
  });

  // Number formatter for tooltip and Y-axis
  const formatNumber = (value: number) => {
    if (value >= 1e9) return `${(value / 1e9).toFixed(2)}B`;
    if (value >= 1e6) return `${(value / 1e6).toFixed(2)}M`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(2)}K`;
    return value.toString();
  };

  // Determine unit based on sortBy value
  const unit = sortBy === 'volume' ? 'Tons' : sortBy === 'cost' ? '$' : '%';

  return (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={dataForBarChart} barGap={30} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickFormatter={(value) => (value.length > 3 ? `${value.substring(0, 3)}...` : value)}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tickFormatter={(value) => `${formatNumber(value)} ${unit}`}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            formatter={(value: any) => `${formatNumber(value)} ${unit}`}
            content={({ payload }: any) => {
              if (payload && payload.length) {
                const { name, Actual, Planned } = payload[0].payload;
                return (
                  <div style={{ background: '#333', color: '#fff', padding: '5px 8px', borderRadius: '4px', minWidth: '120px' }}>
                    <p style={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '12px' }}>{name}</p>
                    <p style={{ margin: 0, fontSize: '12px' }}>Actual: <strong>{formatNumber(Actual)} {unit}</strong></p>
                    <p style={{ margin: 0, fontSize: '12px' }}>Planned: <strong>{formatNumber(Planned)} {unit}</strong></p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend align="center" verticalAlign="bottom" iconType="circle" wrapperStyle={{ paddingTop: 10 }} />
          <Bar dataKey="Actual" fill="#3268C7" name="Actual" barSize={20} />
          <Bar dataKey="Planned" fill="#B9C7E3" name="Planned" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductPerformance;
