/* eslint-disable */
import React from 'react';
import { Row, Col } from 'antd';
import './HeatMapChart.css';

const data = [
  { name: 'Cosmetics', value: 20 },
  { name: 'Entertainment', value: 43 },
  { name: 'Entertainment', value: 43 },
  { name: 'Pet Food', value: 10 },
  { name: 'Grocery', value: 20 },
  { name: 'Electronics', value: 17 },
  { name: 'Electronics', value: 2 },
  { name: 'Pet Food', value: 10 },
  { name: 'Pet Food', value: 10 },
  { name: 'Grocery', value: 20 },
  { name: 'Entertainment', value: 43 },
  { name: 'Pet Food', value: 10 },
  { name: 'Electronics', value: 2 },
];

const getColorClass = (value:any) => {
  if (value >= 40) return 'above-40';
  if (value >= 30) return 'between-30-39';
  if (value >= 20) return 'between-20-29';
  if (value >= 11) return 'between-11-19';
  return 'between-0-10';
};

const UpdatedHeatMapChart: React.FC = () => {
  return (
    <div className="heatmap-container">
      <Row gutter={[8, 8]} justify="start" className="heatmap-grid">
        {data.map((item, index) => (
          <Col key={index} className="heatmap-col">
            <div className={`heatmap-tile ${getColorClass(item.value)}`}>
              <span className="heatmap-value">{item.value}%</span>
              <span className="heatmap-label">{item.name}</span>
            </div>
          </Col>
        ))}
      </Row>
      <div className="heatmap-legend">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <div className="legend-item between-0-10">0% - 10%</div>
          </Col>
          <Col>
            <div className="legend-item between-11-19">11% - 19%</div>
          </Col>
          <Col>
            <div className="legend-item between-20-29">20% - 29%</div>
          </Col>
          <Col>
            <div className="legend-item between-30-39">30% - 39%</div>
          </Col>
          <Col>
            <div className="legend-item above-40">40% - Above</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdatedHeatMapChart;
