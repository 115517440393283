/* eslint-disable */
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SalesAndRevenueTrends: React.FC<{ data: any, type: 'sales' | 'revenue' }> = ({ data, type }) => {

  // Transforming the data for plotting actual vs planned based on the 'type' prop
  const transformedData = data.map((item: any) => ({
    month: item.period,
    actual: type === 'sales' ? item.actual_sales : item.actual_revenue,
    planned: type === 'sales' ? item.planned_sales : item.planned_revenue
  }));

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart data={transformedData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false}  />
        <XAxis dataKey="month"
          axisLine={false} 
          tickLine={false} 
        />
        <YAxis tickFormatter={(value) => `${(value / 1000).toFixed(0)}K`}  axisLine={false} tickLine={false}/>
        <Tooltip formatter={(value) => `${(value as number / 1000).toFixed(2)}K`} />
        {/* Display legend below the chart */}
        <Legend
          align="center"
          verticalAlign="bottom"
          iconType="circle"
          wrapperStyle={{ paddingTop: 10 }}
        />
        <Line type="linear" dataKey="actual" stroke="#3268C7" activeDot={{ r: 8 }} name="Actual" />
        <Line type="linear" dataKey="planned" stroke="#60A5FA" name="Planned" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SalesAndRevenueTrends;
