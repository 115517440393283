import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IListViewReqestPayload,
  IListViewPromotionState,
  IListViewListFormate,
  IListView,
  IBulkDuplicateRequestPayload,
} from './types';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import { formateListView, mapToFilteredData } from '../helper';
import * as listViewSource from './services';
import {
  downloadJsonAsXlsx,
  exportFileEXCELJS,
} from '../../../../utils/dataSheet';

// The initial state of the {{ properCase ModuleName }} container
export const initialState: IListViewPromotionState = {
  listViewList: [],
  totalCount: 0,
  recordsToBeDownloaded: [],
  isBulkDuplicateModalOpen: false,
  listViewAllForBulkDuplicates: [],
  loading: false,
  error: '',
};

// for async action follow blow link
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks
// OR
// https://redux-toolkit.js.org/api/createAsyncThunk

const listViewSlice = createSlice({
  name: 'listView',
  initialState,
  reducers: {
    getListViewListInProgress(state) {
      state.loading = true;
    },
    getListViewListSuccess(
      state,
      { payload }: PayloadAction<IListViewListFormate>,
    ) {
      state.loading = false;
      state.listViewList = payload.data;
      state.totalCount = payload.totalCount;
    },
    getListViewListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getDownloadFileInProgress(state) {
      state.loading = true;
    },
    getDownloadFileSuccess(state) {
      state.loading = false;
      state.recordsToBeDownloaded = [];
      state.listViewAllForBulkDuplicates = [];
    },
    getDownloadFileFailed(state, { payload }) {
      state.loading = false;
      state.recordsToBeDownloaded = [];
      state.listViewAllForBulkDuplicates = [];
      state.error = payload.error;
    },
    setDownloadRecords(state, { payload }) {
      state.recordsToBeDownloaded = payload;
    },
    setIsModalOpenBulkDuplicate(state, { payload }) {
      state.isBulkDuplicateModalOpen = payload;
    },
    bulkDuplicateInProgress(state) {
      state.loading = true;
    },
    bulkDuplicateSuccess(state) {
      state.recordsToBeDownloaded = [];
      state.listViewAllForBulkDuplicates = [];
    },
    bulkDuplicateFailed(state, { payload }) {
      state.loading = false;
      state.recordsToBeDownloaded = [];
      state.listViewAllForBulkDuplicates = [];
      state.error = payload.error;
    },
    getListViewAllInProgress(state) {
      state.loading = true;
    },
    getListViewAllSuccess(
      state,
      { payload }: PayloadAction<IListViewListFormate>,
    ) {
      state.loading = false;
      // state.listViewAllForBulkDuplicates = payload.data;
      state.recordsToBeDownloaded = payload.data;
    },
    getListViewAllFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    setBulkRecords(state, { payload }) {
      state.listViewAllForBulkDuplicates = payload;
    },
  },
});

export const {
  actions: listViewActions,
  reducer: listViewReducer,
  name: sliceKey,
} = listViewSlice;

export const {
  getListViewListInProgress,
  getListViewListSuccess,
  getListViewListFailed,
  getDownloadFileInProgress,
  getDownloadFileSuccess,
  getDownloadFileFailed,
  setDownloadRecords,
  setIsModalOpenBulkDuplicate,
  bulkDuplicateInProgress,
  bulkDuplicateSuccess,
  bulkDuplicateFailed,
  getListViewAllInProgress,
  getListViewAllSuccess,
  getListViewAllFailed,
  setBulkRecords,
} = listViewSlice.actions;

export const getListView =
  (params: IListViewReqestPayload) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getListViewListInProgress());
    try {
      const data = await listViewSource.getListViewApi(params);
      const formateData = formateListView(data);
      dispatch(loadingActions.hideLoader());
      dispatch(getListViewListSuccess(formateData));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(getListViewListFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const downloadExcelFile =
  (user: any, recordsToBeDownloaded: IListView[] = []) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getDownloadFileInProgress());
    try {
      const filteredJson = recordsToBeDownloaded.map(mapToFilteredData);
      // downloadJsonAsXlsx(filteredJson, 'promotions');
      exportFileEXCELJS(filteredJson, user, 'promotions');
      dispatch(loadingActions.hideLoader());
      dispatch(getDownloadFileSuccess());
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(getDownloadFileFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };
export const bulkDuplicateEvent =
  (payload: IBulkDuplicateRequestPayload) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(bulkDuplicateInProgress());
    try {
      const data = await listViewSource.bulkDuplicateEventApi(payload);
      dispatch(bulkDuplicateSuccess());
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Successfully Duplicated',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(bulkDuplicateFailed({ error }));
    }
  };

export const getListViewAll = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getListViewAllInProgress());
  try {
    const data = await listViewSource.getListViewApi({});
    const formateData = formateListView(data);
    dispatch(loadingActions.hideLoader());
    dispatch(getListViewAllSuccess(formateData));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getListViewAllFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};
