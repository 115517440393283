/* eslint-disable */

import React from 'react';
import { List, Statistic, Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';


interface CategoryData {
  category: string;
  percentage: string;
  change: string;
  changeType: 'up' | 'down';
  color: string;
}

const CategoryBrandAnalysis: React.FC<{ data: CategoryData[] }> = ({ data }) => {
  return (
    <List
  itemLayout="horizontal"
  dataSource={data}
  renderItem={(item) => (
    <List.Item style={{ padding: '5px 0' }}>
      <Row style={{ width: '100%' }}>
        {/* Column 1: Avatar and Title */}
        <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: item.color,
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          <span style={{ fontSize: '12px', color: '#333', fontWeight: 'normal' }}>
            {item.category}
          </span>
        </Col>

        {/* Column 2: Percentage */}
        <Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '12px', color: '#333', textAlign: 'center' }}>
            {item.percentage}
          </span>
        </Col>

        {/* Column 3: Statistic */}
        <Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Statistic
            value={item.change}
            precision={1}
            valueStyle={{
              color: item.color,
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            prefix={
              item.changeType === 'up' ? (
                <ArrowUpOutlined />
              ) : (
                <ArrowDownOutlined />
              )
            }
            suffix="%"
          />
        </Col>
      </Row>
    </List.Item>
  )}
/>

  );
};

export default CategoryBrandAnalysis;


