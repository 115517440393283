/* eslint-disable */

import React, { ReactElement } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import DashboardView from './dashboardView2';

export default function Promotion(): ReactElement {
  // get path
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      {/* Route to the dashboard view */}
      <Route exact path={`${path}`} component={DashboardView} />

      {/* Optional: Redirect any other path under this route to /dashboard */}
      {/* <Redirect to={`${path}/dashboard`} /> */}
    </Switch>
  );
}
