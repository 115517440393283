/* eslint-disable */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface Props {
  data: any[];
  sortBy: string; // The selected metric to display (e.g., ROI, Volume, or Cost)
}

const TopPerformingPromotions: React.FC<Props> = ({ data, sortBy }) => {
  // Map your data to extract the necessary fields based on the selected metric
  const dataForBarChart = data.map((item: any, index: any) => {
    // Use the new naming conventions: ActualVolume, ActualCost, and ActualRoi
    const actualValue = sortBy === 'roi' ? item.ActualRoi : sortBy === 'volume' ? item.ActualVolume : item.ActualCost;
    const plannedValue = sortBy === 'roi' ? item.planned_roi : sortBy === 'volume' ? item.planned_volume : item.planned_cost;
    
    return {
      name: item.promotion_header_id || `Promotion ${index + 1}`, // Promotion ID or fallback name
      Actual: actualValue, // Actual value based on the selected metric
      Planned: plannedValue ?? 0, // Fallback to 0 if no planned value exists
    };
  });

  // Format numbers to be in "K" or "M" for readability
  const formatNumber = (value: number) => {
    if (value >= 1000000) return `${(value / 1000000).toFixed(2)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(2)}K`;
    return value.toString();
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={dataForBarChart} barGap={15} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="name"
          tickFormatter={(value) => (value.length > 3 ? `${value.substring(0, 3)}...` : value)}
          axisLine={false} // Remove X-axis line
          tickLine={false} // Remove X-axis tick line
        />
        <YAxis tickFormatter={formatNumber} axisLine={false} tickLine={false} />
        <Tooltip
          formatter={(value: any) => formatNumber(value)} // Format tooltip values
          content={({ payload }: any) => {
            if (payload && payload.length) {
              const { name, Actual, Planned } = payload[0].payload;
              return (
                <div style={{ background: '#000', color: '#fff', padding: '10px', borderRadius: '4px' }}>
                  <p style={{ fontWeight: 'bold' }}>{name}</p>
                  <p>Actual: {formatNumber(Actual)}</p>
                  <p>Planned: {formatNumber(Planned)}</p>
                </div>
              );
            }
            return null;
          }}
        />
        <Legend align="center" verticalAlign="bottom" iconType="circle" wrapperStyle={{ paddingTop: 10 }} />
        {/* Use separate bars for Actual and Planned */}
        <Bar dataKey="Actual" fill="#3268C7" name="Actual" barSize={20} />
        <Bar dataKey="Planned" fill="#B9C7E3" name="Planned" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TopPerformingPromotions;
