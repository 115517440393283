/* eslint-disable */
import { ReactElement, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Statistic,
  Switch,
  DatePicker,
  Select,
  TabsProps,
  Input,
  Button,
  Tabs,
  Spin,
  Dropdown,
  Space,
  MenuProps,
} from 'antd';
import { PlusOutlined, CaretUpOutlined,CaretDownOutlined , DownOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import moment from 'moment'; // Import moment.js for date manipulation
import ProductPerformance from './productPerformance';
import { Line, Column } from '@ant-design/plots';
import DashboardLayout from './calendar';
import CategoryBrandComparision from './categoryBrandComparision';
import RetailerPerformance from './retailerPerformance';
import FinancialAnalysis from './financialAnalysis';
import ChannelPerformance from './channelPerformance';
import SupplyChainImpact from './supplyChainImpect';
import SalesAndRevenueTrends from './salesAndRevenueTrend';
import MarketShareAnalysis from './marketShareAnalysis';
import SalesTrendsByCategoryCategory from './salesTrendsByCatBrand'
import PromotionRoiByCatBrand from './promotionRoiByCatBrand';
import CatBrandCannibalizationAnalysis from './CatBrandCannibalizationAnalysis'
import CategoryAndBrandsPerformance from './catBrandPerformance'
import Filters from './filterBy';
import {
  getDashboardMetrics,
  getDashboardMetricsCategory,
} from './ducks2/slice';

const CategoryAndBrand = (props: any): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    dashboardView: { dashboardMetrics, bargraph },
  } = useAppSelector((state: any) => state);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    // If dashboardMetrics is empty, dispatch the action to get data
    if (Object.keys(dashboardMetrics).length === 0) {
      dispatch(
        getDashboardMetrics({
          startDate: '2024-06-23',
          endDate: '2024-06-30',
        }),
      ).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [dashboardMetrics, dispatch]);

  const [selectedType, setSelectedType] = useState<'sales' | 'revenue'>('sales'); // Default to 'sales'
  const [showWorst, setShowWorst] = useState<boolean>(false); // Toggle for top/worst performing promotions

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '0') {
      setSelectedType('sales');
    } else if (e.key === '1') {
      setSelectedType('revenue');
    }
  };

  const itemsForSalesAndPromo: MenuProps['items'] = [
    {
      label: <a>Sales Trend by Category/Brand</a>,
      key: '0',
    },
    {
      label: <a>Revenue</a>,
      key: '1',
    },
  ];



  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <>
      <div className="dashboard-view-wrapper" style={{ padding: '20px' }}>
     
        <Row gutter={[12, 12]}>
          {/* Second Column - Overall ROI */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
            <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
          Total Sales
        </span>
      }
      value={dashboardMetrics?.totalrevenue?.totalRevenue || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
      prefix="$"
    />
  </Col>
  <Col>
    <Statistic
      value={dashboardMetrics?.totalrevenue?.totalRevenueChange || 0}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.totalrevenue?.totalRevenueChange >= 0 ? '#52C41A' : '#F5222D',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={dashboardMetrics?.totalrevenue?.totalRevenueChange >= 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
      vs last period
    </span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Second Column - Overall ROI */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
   <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={<span style={{ fontSize: '14px', color: '#5C5C5C' }}>Market Share
$</span>}
      value={dashboardMetrics?.overAllRoi?.totalROI || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
      suffix="%"
    />
  </Col>
  <Col>
    <Statistic
      value={dashboardMetrics?.overAllRoi?.totalROI || 0}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.overAllRoi?.totalROIChange > 0 ? '#52C41A' : '#FF4D4F',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={dashboardMetrics?.overAllRoi?.totalROIChange > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>vs last period</span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Third Column - Active Promotion */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
        <Row justify="space-between" align="middle">
  <Col>
    <Statistic
      title={
        <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
          Category/Brand ROI
        </span>
      }
      value={dashboardMetrics?.activePromotion?.activePromotions || 0}
      precision={0}
      valueStyle={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#1E1E1E',
      }}
    />
  </Col>
  <Col>
    <Statistic
      value={Math.abs(dashboardMetrics?.activePromotion?.totalChangeInActive || 0)}
      precision={1}
      valueStyle={{
        color: dashboardMetrics?.activePromotion?.totalChangeInActive >= 0 ? '#52C41A' : '#FF4D4F',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      prefix={
        dashboardMetrics?.activePromotion?.totalChangeInActive >= 0 
          ? <CaretUpOutlined /> 
          : <CaretDownOutlined />
      }
      suffix="%"
    />
    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
      vs last period
    </span>
  </Col>
</Row>


            </Card>
          </Col>
          {/* Fourth Column - Market Share */}
          <Col xs={24} sm={12} lg={6}>
            <Card
              className="custom-card"

              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '100%',
              }}
            >
              <Row justify="space-between" align="middle">
                <Col>
                  <Statistic
                    title={
                      <span style={{ fontSize: '14px', color: '#5C5C5C' }}>
                        Promo Effectiveness

                      </span>
                    }
                    value={10}
                    precision={0}
                    valueStyle={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: '#1E1E1E',
                    }}
                    suffix="%"
                  />
                </Col>
                <Col>
                  <Statistic
                    value={8.5}
                    precision={1}
                    valueStyle={{
                      color: '#52C41A',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    prefix={<CaretUpOutlined />}
                    suffix="%"
                  />
                  <span style={{ fontSize: '12px', color: '#8c8c8c' }}>
                    vs last 7 days
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>

        </Row>
        {/* Second Row - Sales and Revenue Trend */}
        <Row gutter={[16, 16]} style={{ marginTop: '18px' }}>
          <Col span={12}>
            <Card
              title={
                <Dropdown
                  menu={{
                    items: itemsForSalesAndPromo,
                    onClick: handleMenuClick,
                  }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()} style={{ color: 'black', textDecoration: 'none' }}>
                    <Space>
                    Sales Trend by Category/Brand
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              }
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
              }}
            >
              <SalesTrendsByCategoryCategory data={dashboardMetrics.salesAndRevenueTrends || []}  />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Market Share Analysis"
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
              }}
            >
              <MarketShareAnalysis
              />
            </Card>
          </Col>
        </Row>

        {/* Other cards */}
        <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
        <Col span={12}>
        <Card
              title="Promotion ROI by Category/Brand"
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
              }}
            >
              <PromotionRoiByCatBrand />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Category/Brand Comparison"
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '351px',
              }}
            >
              <CategoryBrandComparision />
              </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
          <Col span={12}>
            <Card
              title="Category/Brand Cannibalization Analysis"
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '350px',
              }}
            >
              {/* <ChannelPerformance data={dashboardMetrics.channelPerformance || []} /> */}
              <CatBrandCannibalizationAnalysis />

            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Category/Brand Performance"
              style={{
                borderRadius: '0px',
                boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
                height: '350px',
              }}
            >
              <CategoryAndBrandsPerformance />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CategoryAndBrand;
