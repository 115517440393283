/* eslint-disable */
import React from 'react';
import { Progress, Row, Col, Typography } from 'antd';
import './financialAnalysis.css';

// Helper function to format large numbers with K, M, B suffixes
const formatNumber = (num: number): string => {
  if (num >= 1e9) return `${(num / 1e9).toFixed(1)}B`;
  if (num >= 1e6) return `${(num / 1e6).toFixed(1)}M`;
  if (num >= 1e3) return `${(num / 1e3).toFixed(1)}K`;
  return num.toString();
};

const FinancialAnalysis: React.FC<{ data: any }> = ({ data }) => {
  // Extract values from the response data
  const {
    totalActualCost = 0,
    totalPlannedCost = 0,
    percentUtilized = "0",
  } = data || {};

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center', // Horizontally center the content
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Row justify="center" className="outer-circle">
        <Progress
          type="circle"
          percent={parseFloat(percentUtilized)} // Convert percentUtilized to number for Progress component
          format={() => (
            <>
              <div className="progress-text">Utilised</div>
              <div className="percentage">{percentUtilized}%</div> {/* Utilization percentage */}
            </>
          )}
          width={164.59}
          strokeColor="#3369C8"
          trailColor="#F0F2F5"
        />
      </Row>

      {/* Labels for Planned and Utilised amounts */}
      <Row justify="center" align="middle" style={{ marginTop: '10px', textAlign: 'center' }}>
        <Col span={12}>
          <Typography.Text style={{ fontSize: '12px', color: '#6B7A99' }}>
            <span style={{ color: '#3369C8' }}>●</span> Planned ${formatNumber(totalPlannedCost)}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text style={{ fontSize: '12px', color: '#6B7A99' }}>
            <span style={{ color: '#B9C7E3' }}>●</span> Utilised ${formatNumber(totalActualCost)}
          </Typography.Text>
        </Col>
      </Row>
    </div>
  );
};

export default FinancialAnalysis;
