/* eslint-disable */

import { Row, Col, Tag, Collapse } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useState } from 'react';
import { calculateActivityDuration } from './helper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IconDownOutlined } from 'app/icons/icons';

import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../ListView/helper';
import { headerActions } from '../AppHeader/ducks/slice';
import { formatNumberWithCommas } from 'utils/helper';

const { Panel } = Collapse;

const PromoSummary = ({
  singleObj,
  editPromotionData,
  editPromoPayload,
  promoActivityDuration,
  defaultLevelValue,
  defaultTypeValue,
  defaultDiscountTypesVal,
  defaultBuyUnitOfMeasuresVal,
  showHideHierarchyModal,
}: any): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    promotions: { summaryObject },
  } = useAppSelector((state: any) => state);

  function convertDateFormat(inputDate: string): string {
    // Parse the input date using dayjs
    const parsedDate = dayjs(inputDate);

    // Format the date in the desired format
    const formattedDate = parsedDate.format('MMM DD, YYYY');

    return formattedDate;
  }

  let uniqueHolidays = [...new Set(summaryObject?.promoData?.holidays)];
  return (
    <div className="ut-summary">
      <h1 className="sum-heading">Summary</h1>
      <Collapse
        className="ut-collapse"
        collapsible="header"
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
            <IconDownOutlined />
          </span>
        )}
        expandIconPosition="end"
      >


        <Panel header="Basic Information" key="1" className="ut-collapse">

          <Row gutter={16}>
            <Col span={12}>
              <span>Promotion ID</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {/* {formatNumberWithCommas( */}
                {editPromotionData?.id}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Reference Code</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {/* {formatNumberWithCommas(
              editPromotionData?.code || 0,
            ) || 0} */}
                {editPromotionData?.promoCode}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Program Scheme</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>Program 1</span>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Collapse
        className="ut-collapse"
        collapsible="header"
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
            <IconDownOutlined />
          </span>
        )}
        expandIconPosition="end"
      >
        <Panel header="Duration" key="1" className="ut-collapse">
          <Row gutter={16}>
            <Col span={12}>
              <span>Start Date</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
          
                {editPromotionData?.startDate}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>End Date</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {/* {formatNumberWithCommas(
              summaryObject?.aggregatedCalculations?.totalVolume || 0,
            ) || 0} */}
                {editPromotionData?.endDate}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Activity Duration</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject?.promoData?.promotionDuration
                  ? summaryObject?.promoData?.promotionDuration?.split(' ')[0]
                  : '--'}{' '}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Start Week</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject && summaryObject?.promoData?.weekStart + '-'}{' '}
                {!summaryObject && '--'}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>End Week</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject && summaryObject?.promoData?.weekEnd}{' '}
                {!summaryObject && '--'}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Holiday</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {uniqueHolidays?.length == 0
                  ? 'No Holidays'
                  : uniqueHolidays.map((holiday: any, index: any) => {
                      if (uniqueHolidays.length - 1 == index) {
                        return holiday;
                      } else {
                        return holiday + ', ';
                      }
                    })}{' '}
              </span>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Collapse
        className="ut-collapse"
        collapsible="header"
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
            <IconDownOutlined />
          </span>
        )}
        expandIconPosition="end"
      >
        <Panel header="Promo Tactics" key="1" className="ut-collapse">
          <Row gutter={16}>
            <Col span={12}>
              <span>Level</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject?.promoData?.products
                  ? summaryObject?.promoData?.products[0]?.level
                  : '--'}{' '}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Type</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject?.promoData?.promoType?.shortCode}
                {summaryObject?.promoData?.promoType?.name
                  ? '(' + summaryObject?.promoData?.promoType?.name + ')'
                  : '--'}
              </span>
            </Col>
          </Row>
        </Panel>
        <Panel header="Hierarchy" key="1" className="ut-collapse">
          <Row gutter={16} className="ut-hierarchy-columns">
            <Col span={8}>
              <div className="product-selection-header">
                <span className="product-selection-label">Products</span>
              </div>
            </Col>
            <Col span={8} className="text-right padding-r">
              <span>{summaryObject?.selectionsCount?.productsCount} products</span>
            </Col>
            <Col span={8} className='padding-l'>
            <button
              className="button-like-link"
              onClick={() => showHideHierarchyModal('products')}
            >
              View List
            </button>     
                   </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
                <div className="product-selection-header">
                  <span className="geo-selection-label">Geo</span>
                </div>
            </Col>
            <Col span={8} className="text-right padding-r">
              <span>{summaryObject?.selectionsCount?.geosCount} Distributors</span>
            </Col>
            <Col span={8}>
            <button
              className="button-like-link padding-l"
              onClick={() => showHideHierarchyModal('geo')}
            >
              View List
            </button>     
                   </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
                <div className="product-selection-header">
                  <span className="channel-selection-label">Channel</span>
                </div>
            </Col>
            <Col span={8} className="text-right padding-r">
              <span>{summaryObject?.selectionsCount?.channelsCount} Channels</span>
            </Col>
            <Col span={8}>
            <button
              className="button-like-link padding-l"
              onClick={() => showHideHierarchyModal('channel')}
            >
              View List
            </button>     
                   </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
                <div className="product-selection-header">
                  <span className="outlet-selection-label">Outlet</span>
                </div>
            </Col>
            <Col span={8} className="text-right padding-r">
              <span>{summaryObject?.selectionsCount?.outletsCount} Outlets </span>
            </Col>
            <Col span={8}>
            <button
              className="button-like-link padding-l"
              onClick={() => showHideHierarchyModal('outlet')}
            >
              View List
            </button>     
                   </Col>
          </Row>
        </Panel>
      </Collapse>
      <Collapse
        className="ut-collapse"
        collapsible="header"
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
            <IconDownOutlined />
          </span>
        )}
        expandIconPosition="end"
      >
        <Panel header="Offers" key="1" className="ut-collapse">
          <Row gutter={16}>
            <Col span={12}>
              <span>Level</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject?.promoData?.products
                  ? summaryObject?.promoData?.products[0]?.level
                  : '--'}{' '}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Type</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                {summaryObject?.promoData?.promoType?.shortCode}
                {summaryObject?.promoData?.promoType?.name
                  ? '(' + summaryObject?.promoData?.promoType?.name + ')'
                  : '--'}
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Buy Form (Minimum Buy)</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.purchaseTo || '--'}</span>
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Buy To (Maximum Buy)</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.purchaseTo || '--'}</span>
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Activity Buy Unit of Measure</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                --
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Other Detail</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
                --
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Discount Method</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.discountValue}</span>
          {!summaryObject && '--'}
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Discount</span>
            </Col>
            <Col span={12} className="text-right padding-r">
              <span>
              PKR
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Apply On</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.promoData?.applyOn?.name || '--'}</span>
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Apply To</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.promoData?.applyTo?.name || '--'}</span>
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Apply UOM</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.promoData?.applyUOM?.name}</span>
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Budget</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          {summaryObject?.minimumBudget || summaryObject?.maximumBudget ? (
            <span>
              {summaryObject?.minimumBudget} PKR -{' '}
              {summaryObject?.maximumBudget} PKR
            </span>
          ) : (
            '--'
          )}
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Minimum Budget</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          {summaryObject?.minimumBudget || summaryObject?.maximumBudget ? (
            <span>
              {summaryObject?.minimumBudget} PKR -{' '}
              {summaryObject?.maximumBudget} PKR
            </span>
          ) : (
            '--'
          )}
        </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <span>Maximum Budget</span>
            </Col>
            <Col span={12} className="text-right padding-r">
          {summaryObject?.minimumBudget || summaryObject?.maximumBudget ? (
            <span>
              {summaryObject?.minimumBudget} PKR -{' '}
              {summaryObject?.maximumBudget} PKR
            </span>
          ) : (
            '--'
          )}
        </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};
export default PromoSummary;
