// import { useMixpanel } from 'react-mixpanel-browser';
import { Row, Col, Button } from 'antd';
import {
  PlusOutlined,
  OneToOneOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  DownOutlined,
  CaretDownOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { headerActions } from 'app/modules/AppHeader/ducks/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import {
  setcalendarViewType,
  deleteEvent,
  duplicateEvent,
  submitEvent,
  approvedEvent,
  bulkEvent,
  deleteBulkEvent,
} from 'app/modules/CalendarView/ducks/slice';
import { useHistory, useLocation } from 'react-router-dom';
import QuickCreateEventModal from 'app/modules/CalendarView/quickCreateEventModal';
import {
  getSelectedTemplateDetailSuccess,
  getTemplateDetail,
  getTemplateList,
  isQuickCreateModalOpen,
  setTriggerSubmit,
  updatePromotion,
} from 'app/modules/Promotion/ducks/slice';
import { IconSortOutlined } from 'app/icons/icons';
import ModalDialogSmall from 'app/atoms/ModalDialogSmall';
import { USERLIST } from 'configs/routeNames';
import { ROLES_SLUG } from 'configs/constants';
import UserModal from 'app/modules/Users/userModal';
import {
  getUserListSuccess,
  userModalOpen,
} from 'app/modules/Users/ducks/slice';
import { TSort } from 'app/modules/Users/ducks/types';
import OnBoarding from 'app/molecules/OnBoarding';
import { Dropdown, Menu, Space } from 'antd';
import ArrowIcon from '../../../assets/arrow.png';
import SparklesIcon from '../../../assets/sparkles.png';
import CalendarIcon from '../../../assets/calendar.png';
import GridIcon from '../../../assets/Grid.png';
import ListIcon from '../../../assets/bullet.png';
import FilterIcon from '../../../assets/Filter.png';
import SortIcon from '../../../assets/sort.png';
import AscIcon from '../../../assets/asc.png';
import DscIcon from '../../../assets/dsc.png';
import WeekIcon from '../../../assets/week.png';
import MonthIcon from '../../../assets/month.png';
import QuarterIcon from '../../../assets/quarter.png';
import YearIcon from '../../../assets/year.png';
import CopyIcon from '../../../assets/copy.png';
import AddIcon from '../../../assets/add.png';
import DeleteIcon from '../../../assets/delete-icon.png';
import LineIcon from '../../../assets/line.png';
import ListLightIcon from '../../../assets/list-light.png';
import PercentIcon from '../../../assets/percent.png';
import FileIcon from '../../../assets/file.png';
import RejectIcon from '../../../assets/reject.png';
import SendIcon from '../../../assets/send.png';
import DetailsIcon from '../../../assets/details.png';
import SelectionIcon from '../../../assets/selection.png';
import GraphIcon from '../../../assets/chart.png';
import DownloadIcon from '../../../assets/download.png';
import DuplicateIcon from '../../../assets/duplicate.png';
import DownSelectIcon from '../../../assets/down-select.png';
import SubmitPromoIcon from '../../../assets/PaperPlaneRight.png';
import {
  downloadExcelFile,
  getListViewAll,
  setBulkRecords,
  setIsModalOpenBulkDuplicate,
} from 'app/modules/ListView/ducks/slice';
import SelectTemplateModal from 'app/modules/Promotion/selectTemplateModal';
import BulkDuplicateModal from 'app/modules/Promotion/bulkDuplicateModal';
import { authActions } from '../../modules/Auth/ducks/slice';
import HeaderFilterControlDashboard from '../HeaderFilterControlDashboard';
import { TableOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function HeaderFilterControl() {
  // const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const editViewHeader =
    history.location.pathname.includes('view') ||
    history.location.pathname.includes('edit');
  const isEdit = history.location.pathname.includes('edit');
  const url = new URL(window.location.href); // Replace with your URL
  const promotionId: any = url.pathname.split('/').pop();

  const location = useLocation();
  const currentUrl = location.pathname;
  const isRootUrl = currentUrl === '/';
  const {
    calendarView: { selectedEvent, selectedPromoId },
    promotions: {
      isQuickCreateEventModalOpen,
      editPromoPayloadGState,
      singleObj,
      triggerSubmit,
    },
    auth: { user },
    adminUser: { isUserModalOpen, userList },
    listView,
  } = useAppSelector((state: any) => state);

  const recordsToBeDownloaded = listView?.recordsToBeDownloaded;
  const isBulkDuplicateModalOpen = listView?.isBulkDuplicateModalOpen;
  const listViewData = listView?.listViewAllForBulkDuplicates;

  const [view, setView] = useState('dayGridMonth');
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [modalDialogType, setModalDialogType] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTradeManager, setIsTradeManager] = useState(false);
  const [isFinanaceManager, setIsFinanceManager] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const [sortOrder, setSortOrder] = useState<TSort>('acc');
  const [reason, setReason] = useState('');
  const [isError, setError] = useState(false);
  const [isModalOpenSelectTemplate, setIsModalOpenSelectTemplate] =
    useState(false);
  const [triggerDownloadAll, setTriggerDownloadAll] = useState(false);

  useEffect(() => {
    if (!!user) {
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[2])) {
        setIsAdmin(true);
        dispatch(authActions.setIsAdminStore(true));
      }
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[1])) {
        setIsFinanceManager(true);
        dispatch(authActions.setIsFinanceManagerStore(true));
      }
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[0])) {
        setIsTradeManager(true);
        dispatch(authActions.setIsTradeManagerStore(true));
      }
      return () => {
        setIsAdmin(false);
        setIsFinanceManager(false);
        setIsTradeManager(false);

        dispatch(authActions.setIsAdminStore(false));
        dispatch(authActions.setIsFinanceManagerStore(false));
        dispatch(authActions.setIsTradeManagerStore(false));
      };
    }
  }, [user, dispatch]);
  // Quick Create Event Drawer

  useEffect(() => {
    if (triggerDownloadAll) {
      dispatch(downloadExcelFile(user, recordsToBeDownloaded));
    }
  }, [triggerDownloadAll]);

  const showQuickCreateEventDrawer = () => {
    dispatch(isQuickCreateModalOpen(true));
  };

  const onCloseQuickCreateEventDrawer = () => {
    dispatch(isQuickCreateModalOpen(false));
    dispatch(getSelectedTemplateDetailSuccess({ data: null }));
  };

  // admin user Drawer
  const onShowUserDrawer = () => {
    dispatch(userModalOpen(true));
  };

  const onCloseUserDrawer = () => {
    dispatch(userModalOpen(false));
  };

  //user table sorting
  const handleSort = (type: TSort) => {
    const newList = [...userList];
    newList?.sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      if (type === 'acc') {
        setSortOrder(type);
        return dateA > dateB ? 1 : -1;
      } else {
        setSortOrder(type);
        return dateA > dateB ? -1 : 1;
      }
    });
    dispatch(getUserListSuccess({ data: newList }));
  };

  const onClickFilter = () => {
    // Can add condition based on page
    dispatch(headerActions.setHeaderFilterModal(true));
  };

  const handleModalDialogClose = () => {
    setIsModalDialogOpen(false);
    dispatch(setTriggerSubmit(false));
  };

  const handleDuplicateEvent = () => {
    dispatch(duplicateEvent(promotionId));
    handleModalDialogClose();
  };
  const handleDeleteEvent = async () => {
    dispatch(deleteEvent(promotionId, history));
    // mixpanel?.track(`Promotion Deleted`);
    // mixpanel?.identify(user.email);
    handleModalDialogClose();
  };

  const handleBulkDeleteEvent = async () => {
    dispatch(deleteBulkEvent(listViewData));
    // mixpanel?.track(`Bulk Deleted`);
    // mixpanel?.identify(user.email);
    handleModalDialogClose();
  };

  const handleApproveEvent = (data: any) => {
    if (reason !== '') {
      dispatch(approvedEvent(promotionId, history, data));
      // mixpanel?.track(`Promotion ${data.status}`);
      // mixpanel?.identify(user.email);
      handleModalDialogClose();
      setError(false);
      setReason('');
    } else {
      setError(true);
    }
  };

  const handleBulkEvent = (data: any) => {
    if (reason !== '') {
      dispatch(bulkEvent(listViewData, data));
      // mixpanel?.track(`Promotion Bulk ${data.status}`);
      // mixpanel?.identify(user.email);
      handleModalDialogClose();
      setError(false);
      setReason('');
    } else {
      setError(true);
    }
  };

  const handleEditEvent = async () => {
    if (selectedEvent) {
      history.push(`/promotion/${selectedEvent}`);
    }
  };

  const handleSubmitEvent = async () => {
    dispatch(submitEvent(promotionId, history));
    // mixpanel?.track(`Promotion Submitted`, {
    //   promoCode: singleObj.promoCode,
    // });
    // mixpanel?.identify(user.email);

    // mixpanel?.track('Calendar View');
    // mixpanel?.identify(user.email);

    handleModalDialogClose();
  };

  const showModal = () => {
    setIsModalDialogOpen(true);
  };

  const handleModalDialogEv = (type: string) => {
    setModalDialogType(type);
    showModal();
  };

  const handleDownloadListRecords = async () => {
    if (listView?.recordsToBeDownloaded?.length == 0) {
      await dispatch(getListViewAll());
      setTriggerDownloadAll(true);
    } else {
      dispatch(downloadExcelFile(user, recordsToBeDownloaded));
    }
  };

  const handleBulkCreate = () => {
    dispatch(setIsModalOpenBulkDuplicate(true));
  };

  const handleBulkDelete = () => {
    handleModalDialogEv('BulkDelete');
  };

  const handleSingleDuplicate = (item: any) => {
    dispatch(
      setBulkRecords([
        { ...item, promotion_code: item.promoCode, promo_id: item.id },
      ]),
    );
    dispatch(setIsModalOpenBulkDuplicate(true));
  };

  const handleModalDialogSubmit = () => {
    switch (modalDialogType) {
      case 'Delete':
        handleDeleteEvent();
        break;
      case 'Submit':
        handleSubmitEvent();
        break;
      case 'Duplicate':
        handleDuplicateEvent();
        break;
      case 'Approve':
        handleApproveEvent({
          comment: reason,
          status: 'approved',
        });
        break;
      case 'Reject':
        handleApproveEvent({
          comment: reason,
          status: 'rejected',
        });
        break;
      case 'Sendback':
        handleApproveEvent({
          comment: reason,
          status: 'sentback',
        });
        break;
      case 'BulkApprove':
        handleBulkEvent({
          comment: reason,
          status: 'approve',
        });
        break;
      case 'BulkReject':
        handleBulkEvent({
          comment: reason,
          status: 'reject',
        });
        break;
      case 'BulkDelete':
        handleBulkDeleteEvent();
        break;
      default:
        break;
    }
  };

  const handleSaveChanges = () => {
    dispatch(
      updatePromotion(
        editPromoPayloadGState.payload,
        editPromoPayloadGState.promoId,
      ),
    );
  };
  // 'dayGridMonth,dayGridWeek',
  const handleViewChange = (newView: string) => {
    setView(newView);
    dispatch(setcalendarViewType(newView));
  };

  const handleClickListButton = () => {
    history.push('/promotion/list');
    // mixpanel?.track('List View');
    // mixpanel?.identify(user.email);
  };

  const openSelectTemplateModal = () => {
    setIsModalOpenSelectTemplate(true);
  };

  const createItems = (
    <Menu className="header-dropdown-menu">
      <Menu.Item
        key="1"
        onClick={() => {
          showQuickCreateEventDrawer();
          // mixpanel?.track('Quick Create');
          // mixpanel?.identify(user.email);
        }}
      >
        <img src={ArrowIcon} />
        Quick Create
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          history.push('/promotion/create');
          // mixpanel?.track('Detail promo creation');
          // mixpanel?.identify(user.email);
        }}
      >
        <img src={SparklesIcon} />
        Detail Create
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          openSelectTemplateModal();

          dispatch(getTemplateList());

          // mixpanel?.track('Import promo template');
          // mixpanel?.identify(user.email);
        }}
      >
        <img
          src={DownSelectIcon}
          style={{
            height: 14,
            width: 14,
          }}
        />
        Import from Template
      </Menu.Item>
    </Menu>
  );

  const calendarItems = (
    <Menu className="header-dropdown-menu">
      <Menu.Item
        key="1"
        onClick={() => {
          // mixpanel?.track('Calendar selected week');
          // mixpanel?.identify(user.email);
          handleViewChange('dayGridWeek');
        }}
      >
        <img src={WeekIcon} />
        Week
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          // mixpanel?.track('Calendar selected month');
          // mixpanel?.identify(user.email);
          handleViewChange('dayGridMonth');
        }}
      >
        <img src={MonthIcon} />
        Month
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          // mixpanel?.track('Calendar selected quarter');
          // mixpanel?.identify(user.email);
          handleViewChange('multiMonthYear');
        }}
      >
        <img src={QuarterIcon} />
        Quarter
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          // mixpanel?.track('Calendar selected year');
          // mixpanel?.identify(user.email);
          handleViewChange('dayGridYear');
        }}
      >
        <img src={YearIcon} />
        Year
      </Menu.Item>
    </Menu>
  );
  const calendarItemsReporting = (
    <Menu className="header-dropdown-menu">
      <Menu.Item
        key="1"
        disabled
        onClick={() => {
          // mixpanel?.track('Calendar selected week');
          // mixpanel?.identify(user.email);
          // handleViewChange('dayGridWeek');
        }}
      >
        <img src={WeekIcon} />
        Week
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          // mixpanel?.track('Calendar selected month');
          // mixpanel?.identify(user.email);
          // handleViewChange('dayGridMonth');
        }}
      >
        <img src={MonthIcon} />
        Month
      </Menu.Item>
      <Menu.Item
        key="4"
        disabled
        onClick={() => {
          // mixpanel?.track('Calendar selected year');
          // mixpanel?.identify(user.email);
          // handleViewChange('dayGridYear');
        }}
      >
        <img src={YearIcon} />
        Year
      </Menu.Item>
    </Menu>
  );

  const sortItems = (
    <Menu className="header-dropdown-menu">
      <Menu.Item key="1">
        <img src={AscIcon} />
        Ascending
      </Menu.Item>
      <Menu.Item key="2">
        <img src={DscIcon} />
        Descending
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (triggerSubmit) {
      handleModalDialogEv('Submit');
    }
  }, [triggerSubmit]);

  return (
    <>
      {isTradeManager && currentUrl == '/' ? (
        <HeaderFilterControlDashboard />
      ) : (
        <div className="shadow-bottom ut-bottom-nav">
          <Row align="middle" justify="start" gutter={24}>
            {isAdmin && currentUrl === `/${USERLIST}` && (
              <>
                {/* <Col className="border-r border-solid border-gray-light border-0 mb-5">
              <Row align="middle">
                <Col className="nav-column">
                  <Button type="link" className="active">
                    <span className="icon">
                      <MenuOutlined />
                    </span>
                    <span className="text-xs">List</span>
                  </Button>
                </Col>
              </Row>
              <Row className="column-names">
                <Col>
                  <p className="center text-gray-light font-bold text-xs">
                    Users View
                  </p>
                </Col>
              </Row>
            </Col> */}
                <Col className="mb-5">
                  <Row align="middle">
                    <Col className="nav-column">
                      <Button type="link" onClick={onShowUserDrawer}>
                        <span className="icon">
                          <PlusOutlined />
                        </span>
                        <span className="text-xs">Create</span>
                      </Button>
                      <UserModal
                        isOpen={isUserModalOpen}
                        onClose={onCloseUserDrawer}
                        data={{}}
                        isEdit={false}
                      />
                    </Col>
                    {/* <Col className="nav-column">
                  <Button type="link" onClick={onClickFilter}>
                    <span className="icon">
                      <FilterOutlined />
                    </span>
                    <span className="text-xs">Filter</span>
                  </Button>
                </Col> */}
                    <Col className="nav-column" style={{ minWidth: 150 }}>
                      <div className="flex">
                        <Button
                          type="link"
                          onClick={() =>
                            setShowSorting((prevState) => !prevState)
                          }
                        >
                          <span className="icon">
                            <IconSortOutlined />
                          </span>
                          <span className="text-xs">
                            Sort <DownOutlined style={{ fontSize: 8 }} />
                          </span>
                        </Button>
                        {showSorting && (
                          <div className={`ut-sort-options`}>
                            <Button
                              className={`${sortOrder === 'acc' && 'active'}`}
                              type="link"
                              onClick={() => handleSort('acc')}
                            >
                              <span className="icon">
                                <SortAscendingOutlined />
                              </span>
                              <span className="text-xs">Ascending </span>
                            </Button>
                            <Button
                              className={`${sortOrder === 'dec' && 'active'}`}
                              type="link"
                              onClick={() => handleSort('dec')}
                            >
                              <span className="icon">
                                <SortDescendingOutlined />
                              </span>
                              <span className="text-xs">Descending </span>
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Row className="column-names">
              <Col>
                <p className="center text-gray-light font-bold text-xs">
                  Actions
                </p>
              </Col>
            </Row> */}
              </>
            )}
            {currentUrl !== `/${USERLIST}` && (
              <>
                <Col
                  className="border-r border-solid border-gray-light border-0 mb-5"
                  style={{ padding: 0 }}
                >
                  <Row
                    align="middle"
                    style={{
                      padding: '0px 12px',
                      borderRight: '1px solid rgb(204, 204, 204)',
                    }}
                  >
                    <Col className="nav-column">
                      {editViewHeader && isTradeManager && (
                        <Dropdown
                          overlay={createItems}
                          trigger={['click']}
                          //className="header-dropdown"
                          className="create-btn header-dropdown"
                          arrow
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div className="icon">
                              <PlusOutlined />
                            </div>
                            <Space>
                              Create
                              <CaretDownOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      )}
                      {!editViewHeader &&
                        isTradeManager &&
                        currentUrl !== '/reporting' && (
                          <Dropdown
                            overlay={createItems}
                            trigger={['click']}
                            //className="header-dropdown"
                            className="create-btn header-dropdown"
                            arrow
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <div className="icon">
                                <PlusOutlined />
                              </div>
                              <Space>
                                Create
                                <CaretDownOutlined />
                              </Space>
                            </a>
                          </Dropdown>
                        )}
                      {!editViewHeader &&
                        isTradeManager &&
                        currentUrl === '/reporting' && (
                          <Button
                            type="link"
                            onClick={() => {
                              handleDownloadListRecords();
                              // mixpanel?.track('Download promotion list');
                              // mixpanel?.identify(user.email);
                            }}
                            disabled
                          >
                            <span className="icon2">
                              <DownloadOutlined />
                            </span>
                            <span className="text-xs">Download</span>
                          </Button>
                        )}
                      {isQuickCreateEventModalOpen && (
                        <QuickCreateEventModal
                          isOpen={isQuickCreateEventModalOpen}
                          onClose={onCloseQuickCreateEventDrawer}
                        />
                      )}
                    </Col>
                    {editViewHeader &&
                      isFinanaceManager &&
                      singleObj.status === 'submitted' && (
                        <Col className="nav-column">
                          <Button
                            type="link"
                            onClick={() => handleModalDialogEv('Approve')}
                            className="cursor-pointer-menu"
                          >
                            <span className="icon">
                              <img src={FileIcon} />
                            </span>
                            <span className="text-xs">Approve</span>
                          </Button>
                        </Col>
                      )}
                    {editViewHeader &&
                      isFinanaceManager &&
                      singleObj.status === 'submitted' && (
                        <Col className="nav-column">
                          <Button
                            type="link"
                            onClick={() => handleModalDialogEv('Reject')}
                            className="cursor-pointer-menu"
                          >
                            <span className="icon">
                              <img src={RejectIcon} />
                            </span>
                            <span className="text-xs">Reject</span>
                          </Button>
                        </Col>
                      )}
                    {editViewHeader &&
                      isFinanaceManager &&
                      singleObj.status === 'submitted' && (
                        <Col className="nav-column">
                          <Button
                            type="link"
                            onClick={() => handleModalDialogEv('Sendback')}
                            className="cursor-pointer-menu"
                          >
                            <span className="icon">
                              <img src={SendIcon} />
                            </span>
                            <span className="text-xs">Send Back</span>
                          </Button>
                        </Col>
                      )}
                    {editViewHeader && isTradeManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() => {
                            // handleModalDialogEv('Duplicate')
                            handleSingleDuplicate(singleObj);
                          }}
                        >
                          <span className="icon">
                            <img src={CopyIcon} />
                          </span>
                          <span className="text-xs">Duplicate</span>
                        </Button>
                      </Col>
                    )}
                    {isEdit &&
                      !isFinanaceManager &&
                      (singleObj.status == 'draft' ||
                        singleObj.status == 'sentback') && (
                        <Col className="nav-column">
                          <Button
                            type="link"
                            onClick={() => handleModalDialogEv('Submit')}
                          >
                            <span className="icon">
                              {/* <OneToOneOutlined /> */}
                              <img src={SubmitPromoIcon} width={19} />
                            </span>
                            <span className="text-xs">Submit</span>
                          </Button>
                        </Col>
                      )}
                    {editViewHeader && isTradeManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() => handleModalDialogEv('Delete')}
                          disabled={singleObj.status !== 'draft'}
                        >
                          <span className="icon">
                            <img src={DeleteIcon} />
                          </span>
                          <span className="text-xs">Delete</span>
                        </Button>
                      </Col>
                    )}
                    {/* <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={!selectedEvent}
                    onClick={() => handleModalDialogEv('Delete')}
                  >
                    <span className="icon">
                      <DeleteOutlined />
                    </span>
                    <span className="text-xs">Delete</span>
                  </Button>
                </Col>
                <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={!selectedEvent}
                    onClick={() => handleModalDialogEv('Duplicate')}
                  >
                    <span className="icon">
                      <CopyOutlined />
                    </span>
                    <span className="text-xs">Duplicate</span>
                  </Button>
                </Col> */}

                    {/* <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={(!selectedEvent && isRootUrl) || !isRootUrl}
                    onClick={handleEditEvent}
                    className="edit-btn"
                  >
                    <span className="icon">
                      <EditOutlined />
                    </span>
                    <span className="text-xs">Edit</span>
                  </Button>
                </Col> */}

                    {/* {selectedEvent && isRootUrl && (
                  <Col className="nav-column">
                    <Button
                      type="link"
                      disabled={(!selectedEvent && isRootUrl) || !isRootUrl}
                      onClick={() => handleModalDialogEv('Submit')}
                    >
                      <span className="icon">
                        <OneToOneOutlined />
                      </span>
                      <span className="text-xs">Submit</span>
                    </Button>
                  </Col>
                )} */}
                    {/* <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={!(currentUrl === `/promotion/${promotionId}`)}
                    onClick={handleSaveChanges}
                  >
                    <span className="icon">
                      <SaveOutlined />
                    </span>

                    <span className="text-xs">Save </span>
                  </Button>
                </Col> */}

                    {/* <Col className="nav-column">
                  <Button type="link" disabled>
                    <span className="icon">
                      <ShareAltOutlined />
                    </span>
                    <span className="text-xs">Share</span>
                  </Button>
                </Col> */}
                    {/* {(isRootUrl || currentUrl === '/reporting') && (
                  <Col className="nav-column">
                    <Button type="link" onClick={onClickFilter}>
                      <span className="icon">
                        <FilterOutlined />
                      </span>
                      <span className="text-xs">Filter</span>
                    </Button>
                  </Col>
                )} */}
                  </Row>
                  {isTradeManager && (
                    <Row className="column-names">
                      <Col>
                        <p
                          className="center text-gray-light font-bold text-xs"
                          style={{
                            position: 'relative',
                            top: currentUrl !== '/reporting' ? 6 : 0,
                          }}
                        >
                          {editViewHeader
                            ? 'Promotion'
                            : currentUrl == '/reporting'
                            ? 'Export'
                            : 'Add'}
                        </p>
                      </Col>
                    </Row>
                  )}
                  {!(isTradeManager && isFinanaceManager && isAdmin) &&
                    isFinanaceManager &&
                    singleObj.status === 'submitted' && (
                      <Row className="column-names">
                        <Col>
                          <p className="center text-gray-light font-bold text-xs">
                            {editViewHeader && 'Promotion'}
                          </p>
                        </Col>
                      </Row>
                    )}
                </Col>
                <Col
                  className="border-r border-solid border-gray-light border-0 mb-5"
                  style={{ padding: isFinanaceManager ? 0 : '0px 12px' }}
                >
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      borderRight:
                        currentUrl == '/promotion/list' && !editViewHeader
                          ? '1px solid rgb(204, 204, 204)'
                          : '0px solid',
                    }}
                  >
                    {/* <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={isRootUrl}
                    onClick={() => dispatch(headerActions.showIsCalculations())}
                  >
                    <span className="icon">
                      <InsertRowAboveOutlined />
                    </span>
                    <span className="text-xs">Calculation</span>
                  </Button>
                </Col>
                <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={isRootUrl}
                    onClick={() => dispatch(headerActions.showIsListing())}
                  >
                    <span className="icon">
                      <MenuOutlined />
                    </span>
                    <span className="text-xs">List</span>
                  </Button>
                </Col> */}
                    {/* <Col className="nav-column">
                  <Button
                    type="link"
                    disabled={isRootUrl}
                    onClick={() => dispatch(headerActions.showHierarchy())}
                  >
                    <span className="icon">
                      <ApartmentOutlined />
                    </span>
                    <span className="text-xs">Hierarchy</span>
                  </Button>
                </Col> */}
                    {!editViewHeader &&
                      (isTradeManager || isFinanaceManager || isAdmin) && (
                        <Col
                          className="nav-column"
                          style={{ margin: '6px 0px' }}
                        >
                          <Dropdown
                            overlay={
                              currentUrl !== '/reporting'
                                ? calendarItems
                                : calendarItemsReporting
                            }
                            trigger={
                              [
                                '/calendar',
                                '/',
                                '/promotion/create',
                                '/reporting',
                              ].includes(currentUrl)
                                ? ['click']
                                : []
                            }
                            className="header-dropdown cal-view cal-view"
                            arrow
                          >
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                if (currentUrl == '/promotion/list') {
                                  history.push('/calendar');
                                }
                              }}
                            >
                              <div className="">
                                {/* <img src={CalendarIcon} /> */}
                                <CalendarOutlined
                                  style={{
                                    borderRadius: '50%',
                                    marginBottom: '6px',
                                    display: 'flex',
                                    minHeight: '28px',
                                    minWidth: '28px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '22px',
                                    color: ['/calendar', '/'].includes(
                                      currentUrl,
                                    )
                                      ? '#164291'
                                      : '#5e5d69',
                                  }}
                                />
                              </div>
                              <Space
                                style={{
                                  color: ['/calendar', '/'].includes(currentUrl)
                                    ? '#164291'
                                    : '',
                                }}
                              >
                                Calendar
                              </Space>
                              <CaretDownOutlined
                                style={{
                                  color: ['/calendar', '/'].includes(currentUrl)
                                    ? '#164291'
                                    : '',
                                }}
                              />
                            </a>
                          </Dropdown>
                          {/* <Button type="link" className="cursor-pointer-menu">
                        <span className="icon">
                          <img src={CalendarIcon} />
                        </span>
                        <Dropdown
                          overlay={calendarItems}
                          trigger={['click']}
                          className="header-dropdown"
                          arrow
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>Calendar</Space>
                            <CaretDownOutlined />
                          </a>
                        </Dropdown>
                      </Button> */}
                        </Col>
                      )}
                    {!['/promotion/create', '/reporting'].includes(
                      currentUrl,
                    ) &&
                      (isTradeManager || isFinanaceManager || isAdmin) && (
                        <Col
                          className={`nav-column ml-${editViewHeader ? 0 : 2}`}
                        >
                          <Button type="link" onClick={handleClickListButton}>
                            <span
                              className={`${
                                currentUrl == '/promotion/list'
                                  ? 'icon3'
                                  : 'icon'
                              }`}
                            >
                              <UnorderedListOutlined />
                            </span>
                            <span
                              className="text-xs"
                              style={{
                                color:
                                  currentUrl == '/promotion/list'
                                    ? '#164291'
                                    : '',
                              }}
                            >
                              List
                            </span>
                          </Button>
                        </Col>
                      )}
                    {/* {!['/promotion/create', '/reporting'].includes(
                      currentUrl,
                    ) &&
                      !editViewHeader &&
                      (isTradeManager || isFinanaceManager || isAdmin) && (
                        <Col className="nav-column">
                          <Tooltip placement="top" title="Coming soon">
                            <Button
                              type="link"
                              style={{ margin: '0px 20px' }}
                              disabled
                            >
                              <span className="icon" style={{ opacity: 0.4 }}>
                                <img src={GridIcon} width={30} />
                              </span>
                              <span className="text-xs">Kanban</span>
                            </Button>
                          </Tooltip>
                        </Col>
                      )} */}
                    {editViewHeader && isTradeManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() => {
                            // mixpanel?.track('Hierarchy click');
                            // mixpanel?.identify(user.email);
                            dispatch(headerActions.showHierarchy());
                          }}
                        >
                          <span className="icon">
                            <img src={LineIcon} />
                          </span>
                          <span className="text-xs">Hierarchy</span>
                        </Button>
                      </Col>
                    )}
                    {editViewHeader && isTradeManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() =>
                            dispatch(headerActions.showIsListing())
                          }
                        >
                          <span>
                            <TableOutlined
                              style={{
                                borderRadius: '50%',
                                fontSize: '18px',
                                marginBottom: '6px',
                                display: 'flex',
                                minHeight: '28px',
                                minWidth: '28px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                opacity: 0.5,
                              }}
                            />
                            {/* <img src={ListLightIcon} /> */}
                          </span>
                          <span className="text-xs">Table</span>
                        </Button>
                      </Col>
                    )}
                    {editViewHeader && isTradeManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() => {
                            dispatch(headerActions.showIsCalculations());
                          }}
                        >
                          <span className="icon">
                            <img src={PercentIcon} />
                          </span>
                          <span className="text-xs">Calculations</span>
                        </Button>
                      </Col>
                    )}
                    {/* {editViewHeader && isFinanaceManager && (
                      <Col className="nav-column">
                        <Button type="link" disabled>
                          <span className="icon">
                            <img src={DetailsIcon} />
                          </span>
                          <span className="text-xs">Detail</span>
                        </Button>
                      </Col>
                    )} */}
                    {/* {editViewHeader && isFinanaceManager && (
                      <Col className="nav-column">
                        <Button type="link" disabled>
                          <span className="icon">
                            <img src={SelectionIcon} />
                          </span>
                          <span className="text-xs">Selection</span>
                        </Button>
                      </Col>
                    )} */}
                    {editViewHeader && isFinanaceManager && (
                      <Col className="nav-column">
                        <Button
                          type="link"
                          onClick={() => {
                            // mixpanel?.track('Hierarchy click');
                            // mixpanel?.identify(user.email);
                            dispatch(headerActions.showHierarchy());
                          }}
                        >
                          <span className="icon">
                            <img src={GraphIcon} />
                          </span>
                          <span className="text-xs">Hierarchy</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {(isTradeManager || isFinanaceManager || isAdmin) && (
                    <Row className="column-names">
                      <Col>
                        <p className="center text-gray-light font-bold text-xs">
                          {editViewHeader ? 'Product(s)' : 'Promotion Views'}
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
                {!editViewHeader &&
                  (isTradeManager || isFinanaceManager || isAdmin) && (
                    <Col
                      className="border-r border-solid border-gray-light border-0 mb-5"
                      style={{ padding: 0 }}
                    >
                      <Row align="middle" justify="space-between">
                        {false && (
                          <Col className="nav-column">
                            <Button
                              type="link"
                              style={{ marginLeft: 0 }}
                              disabled
                            >
                              <span className="icon">
                                <img
                                  src={FilterIcon}
                                  style={{ opacity: '0.4' }}
                                />
                              </span>
                              <span className="text-xs">Filter</span>
                            </Button>
                          </Col>
                        )}
                        {false && (
                          <Col className="nav-column">
                            <Button
                              type="link"
                              // className="cursor-pointer-menu"
                              disabled
                              style={{ opacity: 0.5 }}
                            >
                              <span className="icon">
                                <img src={SortIcon} style={{ opacity: 0.6 }} />
                              </span>
                              <Dropdown
                                overlay={sortItems}
                                trigger={['click']}
                                className="header-dropdown"
                                arrow
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <Space>Sort</Space>
                                  <CaretDownOutlined />
                                </a>
                              </Dropdown>
                            </Button>
                          </Col>
                        )}
                        {currentUrl === '/promotion/list' && (
                          <Col className="nav-column ms-2">
                            <Button
                              type="link"
                              onClick={() => {
                                handleDownloadListRecords();
                                // mixpanel?.track('Download promotion list');
                                // mixpanel?.identify(user.email);
                              }}
                            >
                              <span className="icon2">
                                <DownloadOutlined />
                              </span>
                              <span className="text-xs">Download</span>
                            </Button>
                          </Col>
                        )}
                        {currentUrl === '/promotion/list' &&
                          isFinanaceManager && (
                            <Col className="nav-column ms-4">
                              <Button
                                type="link"
                                onClick={() => {
                                  handleModalDialogEv('BulkApprove');
                                }}
                                disabled={listViewData.length == 0}
                              >
                                <span className="icon">
                                  <img
                                    src={FileIcon}
                                    style={{
                                      opacity:
                                        listViewData.length == 0 ? 0.5 : 1,
                                    }}
                                  />
                                </span>
                                <span className="text-xs">Approve all</span>
                              </Button>
                            </Col>
                          )}
                        {currentUrl === '/promotion/list' &&
                          isFinanaceManager && (
                            <Col className="nav-column ms-4">
                              <Button
                                type="link"
                                onClick={() => {
                                  handleModalDialogEv('BulkReject');
                                }}
                                disabled={listViewData.length == 0}
                              >
                                <span className="icon">
                                  <img
                                    src={RejectIcon}
                                    style={{
                                      opacity:
                                        listViewData.length == 0 ? 0.5 : 1,
                                    }}
                                  />
                                </span>
                                <span className="text-xs">Reject all</span>
                              </Button>
                            </Col>
                          )}
                        {currentUrl === '/promotion/list' && isTradeManager && (
                          <Col className="nav-column ms-2">
                            <Button
                              type="link"
                              onClick={() => {
                                handleBulkCreate();
                              }}
                            >
                              <span className="icon">
                                <img src={CopyIcon} style={{ width: 22 }} />
                              </span>
                              <span className="text-xs">Duplicate</span>
                            </Button>
                          </Col>
                        )}
                        {currentUrl === '/promotion/list' && (
                          <Col className="nav-column ms-2">
                            <Button
                              type="link"
                              disabled={listViewData.length == 0}
                              onClick={() => {
                                handleBulkDelete();
                                // mixpanel?.track('Bulk delete');
                                // mixpanel?.identify(user.email);
                              }}
                            >
                              <span className="icon">
                                <img
                                  src={DeleteIcon}
                                  style={{
                                    width: 22,
                                    opacity: listViewData.length == 0 ? 0.5 : 1,
                                  }}
                                />
                              </span>
                              <span className="text-xs">Delete all</span>
                            </Button>
                          </Col>
                        )}
                      </Row>
                      <Row className="column-names">
                        {currentUrl == '/promotion/list' && (
                          <Col>
                            <p className="center text-gray-light font-bold text-xs">
                              Manage
                            </p>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                {/* <Col className="mb-5 cal-view">
              <Row align="middle" gutter={4}>
                <Col className="nav-column">
                  <Button
                    type="link"
                    onClick={() => handleViewChange('dayGridWeek')}
                    className={`${view === 'dayGridWeek' ? 'active' : ''}`}
                  >
                    <span className="icon">
                      <PicCenterOutlined />
                    </span>
                    <span className="text-xs">Week</span>
                  </Button>
                </Col>
                <Col className="nav-column">
                  <Button
                    type="link"
                    className={`${view === 'dayGridMonth' ? 'active' : ''}`}
                    onClick={() => handleViewChange('dayGridMonth')}
                  >
                    <span className="icon">
                      <CalendarOutlined />
                    </span>
                    <span className="text-xs">Month</span>
                  </Button>
                </Col>
                <Col className="nav-column">
                  <Button
                    type="link"
                    disabled
                    className={`${view === 'dayGridYear' ? 'active' : ''}`}
                    onClick={() => handleViewChange('dayGridYear')}
                  >
                    <span className="icon">
                      <BuildOutlined />
                    </span>
                    <span className="text-xs">Year</span>
                  </Button>
                </Col>
              </Row>
              <Row className="column-names">
                <Col>
                  <p className="center text-gray-light font-bold text-xs">
                    View
                  </p>
                </Col>
              </Row>
            </Col> */}
              </>
            )}
          </Row>
          <ModalDialogSmall
            isOpen={isModalDialogOpen || triggerSubmit}
            handleModalCancel={handleModalDialogClose}
            handleModalSubmit={handleModalDialogSubmit}
            modalDialogType={modalDialogType}
            promoId={singleObj.promoCode}
            setReason={setReason}
            setError={setError}
            isError={isError}
            reason={reason}
            createdByUser={
              singleObj?.createdByUserDetail || {
                firstName: 'some',
                lastName: 'one',
              }
            }
            count={listViewData.length}
          />
          <OnBoarding />
          <SelectTemplateModal
            isModalOpen={isModalOpenSelectTemplate}
            handleCancel={() => {
              setIsModalOpenSelectTemplate(false);
            }}
            handleOk={async (selectedTemplateId: any) => {
              await dispatch(getTemplateDetail(selectedTemplateId));
              setIsModalOpenSelectTemplate(false);
              showQuickCreateEventDrawer();
            }}
          />
          <BulkDuplicateModal
            isModalOpen={isBulkDuplicateModalOpen}
            handleCancel={() => {
              dispatch(setIsModalOpenBulkDuplicate(false));
            }}
            handleOk={async () => {
              dispatch(setIsModalOpenBulkDuplicate(false));
            }}
          />
        </div>
      )}
    </>
  );
}
