import request from 'utils/axios';

import { httpVerbs } from 'configs';
import dayjs from 'dayjs';

import {
  IBulkDuplicateRequestPayload,
  IListViewPromotionListResponse,
  IListViewReqestPayload,
} from './types';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getListViewApi = async (
  payload: IListViewReqestPayload,
): Promise<IListViewPromotionListResponse> => {
  const url = `${BASE_URL}/promotions/listview`;

  // Parse `startDate` and `endDate` from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const startDate = urlParams.get('startDate');
  const endDate = urlParams.get('endDate');

  // Add `startDate` and `endDate` to the payload if they are present in the URL
  const enhancedPayload = {
    ...payload,
    ...(startDate ? { startDate: dayjs(startDate).format('YYYY-MM-DD') } : {}),
    ...(endDate ? { endDate: dayjs(endDate).format('YYYY-MM-DD') } : {}),
  };

  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: enhancedPayload,
  };

  console.log('params: ', params);
  const response = await request(params);
  return response;
};

export const bulkDuplicateEventApi = async (
  payload: IBulkDuplicateRequestPayload,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/duplicate`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};
