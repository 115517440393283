import { ReactElement } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Form from './form';

export default function Reporting(): ReactElement {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={Form} />
    </Switch>
  );
}
