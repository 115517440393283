/* eslint-disable */
import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ZAxis,
  ResponsiveContainer,
} from 'recharts';

// Sample data
const data = [
  { x: 2, y: 1400, z: 80, category: 'Grocery' },
  { x: 3, y: 1200, z: 50, category: 'Grocery' },
  { x: 3, y: 1250, z: 30, category: 'Men Clothing' },
  { x: 4, y: 1275, z: 40, category: 'Women Clothing' },
  { x: 5, y: 1450, z: 90, category: 'Electronics' },
  { x: 5, y: 1300, z: 60, category: 'Electronics' },
  { x: 6, y: 1350, z: 80, category: 'Grocery' },
  { x: 7, y: 1425, z: 70, category: 'Electronics' },
  { x: 7, y: 1250, z: 50, category: 'Grocery' },
  { x: 8, y: 1300, z: 30, category: 'Grocery' },
  { x: 10, y: 1375, z: 60, category: 'Electronics' },
  { x: 11, y: 1400, z: 40, category: 'Men Shoes' },
];

const BubbleChartComponent: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ScatterChart
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="x"
          name="Month"
          tick={{ fontSize: 12 }}
          label={{ value: 'Month', position: 'insideBottom', offset: -5 }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Sales"
          tick={{ fontSize: 12 }}
          label={{ value: 'Sales', angle: -90, position: 'insideLeft' }}
          domain={[1100, 1500]}
        />
        <ZAxis type="number" dataKey="z" range={[100, 1000]} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend verticalAlign="bottom" align="center" />

        <Scatter
          name="Grocery"
          data={data.filter((item) => item.category === 'Grocery')}
          fill="#595959"
        />
        <Scatter
          name="Electronics"
          data={data.filter((item) => item.category === 'Electronics')}
          fill="#87CEFA"
        />
        <Scatter
          name="Women Clothing"
          data={data.filter((item) => item.category === 'Women Clothing')}
          fill="#FFD700"
        />
        <Scatter
          name="Men Clothing"
          data={data.filter((item) => item.category === 'Men Clothing')}
          fill="#32CD32"
        />
        <Scatter
          name="Men Shoes"
          data={data.filter((item) => item.category === 'Men Shoes')}
          fill="#ADD8E6"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default BubbleChartComponent;
