/* eslint-disable */

import React from 'react';

// State category data with different shades of blue
const stateCategoryData = [
  { state: 'AK', color: '#506ECE' },  // Darker blue for AK
  { state: 'HI', color: '#A6C8FA' },  // Lighter blue for HI
  { state: 'TX', color: '#3357B0' },  // Dark blue for TX
  { state: 'CA', color: '#A6C8FA' },  // Lighter blue for CA
  { state: 'NY', color: '#506ECE' },  // Darker blue for NY
  { state: 'FL', color: '#A6C8FA' },  // Lighter blue for FL
  { state: 'WA', color: '#506ECE' },  // Darker blue for WA
  { state: 'MA', color: '#506ECE' },  // Darker blue for MA
  { state: 'GA', color: '#A6C8FA' },  // Lighter blue for GA
  { state: 'OR', color: '#3357B0' },  // Blue for OR
  { state: 'NV', color: '#A6C8FA' },  // Lighter blue for NV
  { state: 'ID', color: '#506ECE' },  // Darker blue for ID
  { state: 'MT', color: '#506ECE' },  // Darker blue for MT
  { state: 'ND', color: '#506ECE' },  // Darker blue for ND
  { state: 'SD', color: '#A6C8FA' },  // Lighter blue for SD
  { state: 'AZ', color: '#3357B0' },  // Blue for AZ
  // Add more states if needed...
];

// Layout for U.S. states arranged like a grid
const stateGrid = [
  ['AK', ' ', ' ', ' ', 'WA', 'OR', 'ID', 'MT', 'ND', 'MN'],
  [' ', ' ', ' ', ' ', 'CA', 'NV', 'UT', 'CO', 'WY', 'NE'],
  ['HI', ' ', ' ', ' ', 'AZ', 'NM', 'TX', 'OK', 'KS', 'IA'],
  [' ', ' ', ' ', ' ', ' ', ' ', ' ', 'AR', 'MO', 'WI'],
  [' ', ' ', ' ', ' ', ' ', ' ', ' ', 'LA', 'MS', 'MI'],
  [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', 'TN', 'IN'],
  [' ', ' ', ' ', ' ', 'FL', ' ', ' ', 'AL', 'GA', 'OH'],
  [' ', ' ', ' ', ' ', 'SC', 'NC', 'KY', 'PA', 'NY', 'MA'],
];

// Custom CSS for the grid
const gridContainerStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(10, 50px)',  // Larger squares for better readability
  // gridTemplateRows: 'repeat(8, 50px)',      // Adjust size of rows
  gap: '3px',                               // Adjust gap between squares for better layout
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: '#fff',                  // White background for clarity
  // padding: '10px',
  // borderRadius: '10px',                     // Rounded corners for container
  // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for subtle elevation
};

const squareStyle: React.CSSProperties = {
  width: '20px',                            // Larger square size
  height: '20px',                           // Matching height and width
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',                           // White text to contrast with blue background
  fontWeight: 'bold',
  fontSize: '8px',                         // Slightly larger font for readability
  borderRadius: '6px',                      // Round corners for better design
  textAlign: 'center',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Add slight shadow for each square
};

const GridUSMap: React.FC = () => {
  return (
      <div style={gridContainerStyle}>
        {stateGrid.map((row, rowIndex) =>
          row.map((abbr, colIndex) => {
            const stateData = stateCategoryData.find((state) => state.state === abbr);
            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                style={{
                  ...squareStyle,
                  backgroundColor: stateData ? stateData.color : '#3268C7',  // Default gray for undefined states
                }}
              >
                {abbr.trim() ? abbr : ''}
              </div>
            );
          })
        )}
      </div>
  );
};

export default GridUSMap;
