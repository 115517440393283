import { BASE_URL, httpVerbs } from 'configs';
import request from 'utils/axios';
import { IHTTPRequestArgs, Method } from 'configs/types';

export const addAppHierarchy = async (hierarchy: string[]): Promise<any> => {
  const url = `${BASE_URL}/approval-hierarchy`;
  const payload = {
    hierarchy: hierarchy,
    approvalStatuses: {}, // Empty approval statuses as required
  };

  const params: IHTTPRequestArgs = {
    url: url,
    method: 'POST',
    data: payload, // Pass payload as data
  };

  // Make the API request using the configured Axios instance
  const response = await request(params);
  return response;
};

export const getAppHierarchy = async (): Promise<any> => {
  const url = `${BASE_URL}/approval-hierarchy`; // Ensure this is the correct API URL

  const params: IHTTPRequestArgs = {
    url: url,
    method: 'GET' as Method, // GET request to fetch the hierarchy
  };

  // Make the API request using Axios
  const response = await request(params);
  return response.data; // Return the response data
};

export const deleteAppHierarchy = async (): Promise<any> => {
  const url = `${BASE_URL}/approval-hierarchy`; // Adjust the API URL

  const params: IHTTPRequestArgs = {
    url: url,
    method: 'DELETE', // DELETE request to remove hierarchy
  };

  const response = await request(params);
  return response;
};

export const getUserListAPI = async (): Promise<any> => {
  console.log('hereeee usersssss');
  const url = `${BASE_URL}/administration/all-users`;
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.GET as Method,
  };
  const response = await request(params);
  return response;
};
