/* eslint-disable */

import React from 'react';
import { Row, Col, Progress, List, Typography } from 'antd';
import './overAllPromotion.css';

interface IOverviewData {
  totalPromotions: string;
  activePromotions: string;
  completedPromotions: string;
  totalROI: string;
  productsPromoted: string;
  upcomingPromotions: string;
  getTotalSpend: number | undefined;
}

interface OverAllPromotionProps {
  overviewData: IOverviewData;
  startDate: string;
  endDate: string;
}

const formatNumber = (num: number | undefined): string => {
  if (typeof num !== 'number' || isNaN(num)) {
    return '$0.00';
  }
  if (num >= 1e9) {
    return `$${(num / 1e9).toFixed(2)} B`;
  } else if (num >= 1e6) {
    return `$${(num / 1e6).toFixed(2)} M`;
  } else if (num >= 1e3) {
    return `$${(num / 1e3).toFixed(2)} K`;
  }
  return `$${num.toFixed(2)}`;
};

const OverAllPromotion: React.FC<OverAllPromotionProps> = ({
  overviewData,
  startDate,
  endDate,
}) => {
  const {
    totalPromotions,
    activePromotions,
    completedPromotions,
    totalROI,
    productsPromoted,
    upcomingPromotions,
    getTotalSpend = 0,
  } = overviewData;

  const data = [
    { label: 'Total Promotions', value: totalPromotions, link: 'View Detail' },
    { label: 'Active', value: activePromotions, link: 'View Detail' },
    { label: 'Completed', value: completedPromotions, link: 'View Detail' },
    { label: 'ROI', value: totalROI, link: 'View Detail' },
    { label: 'Products Promoted', value: productsPromoted, link: 'View Detail' },
    { label: 'Upcoming', value: upcomingPromotions, link: 'View Detail' },
  ];

  const formattedTotalSpend = formatNumber(getTotalSpend);

  return (
    <Row justify="center" align="middle" style={{ height: '100%', textAlign: 'center' }}>
      <Col xs={24} md={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Progress
          type="dashboard"
          percent={(getTotalSpend / 1e6) * 10}
          format={() => (
            <>
              <Typography.Title level={4} style={{ fontSize: '37.01px', margin: 0, fontWeight: 'bold' }}>
                {formattedTotalSpend}
              </Typography.Title>
              <Typography.Text style={{ fontSize: '17px' }}>
                Total Spend
              </Typography.Text>
            </>
          )}
          strokeColor={{ '0%': '#3369C8', '100%': '#BAC8E3' }}
          strokeWidth={6}
          width={276}
        />
      </Col>
      <Col xs={24} md={14} style={{  justifyContent: 'center'  }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item style={{ padding: '4px 0', borderBottom: 'none', textAlign: 'center' }}>
              <Row style={{ width: '100%' }} justify="space-between" align="middle">
                <Col span={10} style={{ textAlign: 'left' }}>
                  <span
                    style={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: '#3276CE',
                      display: 'inline-block',
                      marginRight: '8px',
                      marginLeft: "20px"
                    }}
                  ></span>
                  <Typography.Text  style={{ fontSize: '12.04px' }}>
                    {item.label}
                  </Typography.Text>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <Typography.Text style={{ fontSize: '12px' }}>
                    {item.value}
                  </Typography.Text>
                </Col>
                <Col span={8} style={{ textAlign: 'center' }}>
                  <a
                    style={{ fontSize: '10px' }}
                    href={`${window.location.origin}/demo/promotion/list?startDate=${startDate}&endDate=${endDate}`}
                  >
                    {item.link}
                  </a>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default OverAllPromotion;
