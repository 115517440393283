/* eslint-disable */
import { ReactElement, useState } from 'react';
import { Col, Row, DatePicker, Select, Input, Button, Spin } from 'antd';
import { EditOutlined, DownloadOutlined, PrinterOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { getDashboardMetrics } from '../../modules/DashboardView/ducks2/slice';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function HeaderFilterControlDashboard(): ReactElement {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Handle date change with the correct types
  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      const start = dates[0].format('YYYY-MM-DD');
      const end = dates[1].format('YYYY-MM-DD');
      setStartDate(start);
      setEndDate(end);

      dispatch(
        getDashboardMetrics({
          startDate: start || undefined,
          endDate: end || undefined,
        }) as any
      );
    } else {
      setStartDate(null);
      setEndDate(null);
      dispatch(getDashboardMetrics({}) as any);
    }
  };

  // Export to PDF function with loader
  const exportToPDF = () => {
    setLoading(true);
    const dashboardElement = document.getElementById('dashboard-content');
    if (dashboardElement) {
      html2canvas(dashboardElement).then((canvas: HTMLCanvasElement) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        if (imgHeight > pageHeight) {
          position = imgHeight - pageHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        }

        pdf.save('dashboard.pdf');
        setLoading(false);
      }).catch(() => setLoading(false));
    }
  };

  return (
    <Row gutter={[16, 16]} justify="space-between" align="middle" style={{ marginTop: '12px' }}>
      <Col>
        <Row gutter={[8, 16]} align="middle">
          <Col>
            <span style={{ fontSize: '16px', fontWeight: '500' , marginLeft: '15px' }}>Filter By:</span>
          </Col>
          <Col>
          <RangePicker
  onChange={handleDateChange}
  format="YYYY-MM-DD"
  defaultValue={[dayjs('2024-01-01', 'YYYY-MM-DD'), dayjs('2024-06-30', 'YYYY-MM-DD')]}
  style={{ width: 220 }}
/>

          </Col>
          {/* <Col>
            <Select
              showSearch
              placeholder="Location"
              style={{ width: 120 }}
              options={[{ value: '1', label: 'Not Identified' }]}
            />
          </Col>
          <Col>
            <Select
              showSearch
              placeholder="Category / Brand"
              style={{ width: 150 }}
              options={[{ value: '1', label: 'Not Identified' }]}
            />
          </Col>
          <Col>
            <Search placeholder="Type to search..." allowClear enterButton={<SearchOutlined />} style={{ width: 200 }} />
          </Col>
          <Col>
            <Button
              icon={<FilterOutlined />}
              style={{
                border: '1px solid #d9d9d9',
                backgroundColor: 'white',
                color: '#5c5c5c',
              }}
            >
              Filters
            </Button>
          </Col> */}
        </Row>
      </Col>

      <Col>
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#001A40', border: 'none' }}
          /> */}
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{ backgroundColor: '#003E92', border: 'none'  , marginRight: '15px' }}
            onClick={exportToPDF}
          >
            {loading ? <Spin size="small" /> : 'Export'}
          </Button>
        
        </div>
      </Col>
    </Row>
  );
}
