import React, { ReactElement } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CalendarView from './calendarView';

export default function Promotion(): ReactElement {
  // get path
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={CalendarView} />
    </Switch>
  );
}
