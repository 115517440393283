/* eslint-disable */
import React from 'react';
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import './RetailerPerformance.css'; // Assuming you have a CSS file for additional styling

const RetailerPerformance: React.FC<{ data: any }> = ({ data }) => {
  // Formatter for large numbers with unit
  const formatNumber = (num: number): string => {
    if (num >= 1e9) {
      return `${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(1)}M`;
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(1)}K`;
    }
    return num.toString();
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart data={data} margin={{ top: 20, right: 35, left: 50, bottom: 37 }}>
        <defs>
          <linearGradient id="colorScales" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3268C7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#3268C7" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        
        {/* Y-Axis Configuration with formatter and unit label */}
        <YAxis
          label={{
            value: "Tons", 
            angle: -90, 
            position: "insideLeft", 
            offset: -5, 
            style: { fill: "#5C5C5C", fontSize: 12 }
          }}
          tick={{ fill: "#5C5C5C", fontSize: 12 }}
          width={50} // Increased width to prevent overlap
          axisLine={false} // Removes Y-axis line
          tickFormatter={formatNumber} // Apply number formatting
        />

        {/* X-Axis Configuration */}
        <XAxis
          dataKey="Date"
          tick={{ fill: "#5C5C5C", fontSize: 12 }}
          padding={{ left: 20, right: 20 }}
          label={{
            value: "Date", 
            position: "insideBottom", 
            offset: -5, 
            style: { fill: "#5C5C5C", fontSize: 12 }
          }}
          axisLine={false} // Removes X-axis line
        />

        <Tooltip formatter={(value: any) => `${formatNumber(value)} Tons`} />
        
        <Area
          type="linear"
          dataKey="scales"
          stroke="#3268C7"
          fill="url(#colorScales)"
          strokeWidth={2}
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RetailerPerformance;
