/* eslint-disable */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

interface ChannelPerformanceData {
  channel_name: string;
  total_sales: number;
}

interface Props {
  data: ChannelPerformanceData[];
}

const ChannelPerformanceChart: React.FC<Props> = ({ data }) => {
  const COLORS = ['#3268C7', '#B9C7E3', '#B9C7E3', '#B9C7E3', '#B9C7E3'];

  // Set a default unit for the chart, such as "Tons" or "$"
  const unit = "Tons"; // Change this to "$" or "%" as needed

  // Prepare data for BarChart
  const finalData = data.map((prop: ChannelPerformanceData, index: number) => ({
    name: prop.channel_name, // Channel name
    value: prop.total_sales, // Total sales for each channel
    fill: COLORS[index % COLORS.length], // Assign colors to bars
  }));

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={finalData} layout="vertical" barSize={20}>
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => `${value.toLocaleString()} ${unit}`}
        />
        <YAxis
          dataKey="name"
          type="category"
          width={100} // Increase width to ensure full channel names are visible
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12, fill: '#333' }} // Adjust font size and color for better readability
        />
        <Tooltip formatter={(value: number) => `${value.toLocaleString()} ${unit}`} />
        <Legend
          verticalAlign="bottom"
          align="center"
          wrapperStyle={{ paddingBottom: 10 }}
          payload={[
            {
              value: `Sales (${unit})`,
              type: 'square',
              color: '#000',
            },
          ]}
        />
        <Bar dataKey="value" name="Sales">
          {finalData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChannelPerformanceChart;
