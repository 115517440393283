/* eslint-disable */
// import { useMixpanel } from 'react-mixpanel-browser';
import {
  Row,
  Col,
  Button,
  Dropdown,
  Avatar,
  Badge,
  Drawer,
  List,
  Spin,
  Input,
} from 'antd';
import type { MenuProps } from 'antd';
// import Logo from '../../../assets/logo-grey.png';
import Logo from '../../../assets/uptrade-logo.png';


import {
  BellOutlined,
  DownOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { removeTokenFromLocal } from 'app/modules/Auth/ducks/services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authActions } from 'app/modules/Auth/ducks/slice';
import { useEffect, useRef, useState } from 'react';
const io = require('socket.io-client/dist/socket.io');
import placeHolder from '../../../assets/akismet.jpeg';
import { BASE_URL } from 'configs';
import { ROLES_SLUG } from 'configs/constants';
import { INotification } from 'app/modules/AppHeader/ducks/type';
import { addStrongTags, formateUTCDateTime } from 'utils/helper';
import {
  clearSearchAndGetRecent,
  getNotifications,
  getSearchResult,
  resetNotificationsCount,
} from 'app/modules/AppHeader/ducks/slice';
import { Status } from 'app/modules/CalendarView/helper';
import SearchEvent from './searchEvent';
import { IconAdminOutlined } from 'app/icons/icons';
import { SettingOutlined } from '@ant-design/icons';
import { getPromotionsListSuccess } from 'app/modules/Promotion/ducks/slice';
import { getListView } from 'app/modules/ListView/ducks/slice';
import { PAGE_SIZE_LIST_VIEW } from 'app/modules/ListView/helper';
import { getCalendarView } from 'app/modules/CalendarView/ducks/slice';
import NotificationSound from '../../../assets/notification-sound.mp3';

type User = {
  name: string;
};
const { Search } = Input;
interface HeaderProps {
  user?: User;
  onLogin: () => void;
  onLogout: () => void;
  onCreateAccount: () => void;
}

const menu: MenuProps['items'] = [
  {
    key: 'logout',
    label: 'Logout',
  },
];

export interface IState {
  auth: {
    user?: any;
  };
  appHeader: {
    loading: boolean;
    notifications: INotification[];
    recentSearch: any;
    searchResult: any;
  };
}
export const Header = ({ onLogin, onLogout, onCreateAccount }: HeaderProps) => {
  // const mixpanel = useMixpanel();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    auth: { user },
    appHeader: { loading, notifications, recentSearch, searchResult },
  } = useAppSelector((state: IState) => state);
  const location = useLocation();
  const currentUrl = location.pathname;
  const isRootUrl = currentUrl === '/';

  const [unreadNotiCountState, setUnreadNotiCount] = useState(0);
  const [openNotification, setOpenNotification] = useState(false);

  const [showNotificationDot, setShowNotificationDot] = useState(false);
  const [showAllNotification, setShowAllNotification] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTradeManager, setIsTradeManager] = useState(false);
  const [isFinanaceManager, setIsFinanceManager] = useState(false);

  const audioPlayer: any = useRef(null);

  function playAudio() {
    audioPlayer?.current?.play();
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL_OLD, {
      transports: ['websocket'],
      query: { userId: user?.id },
    });
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    if (!!user) {
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[2])) {
        setIsAdmin(true);
        dispatch(authActions.setIsAdminStore(true));
      }
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[1])) {
        setIsFinanceManager(true);
        dispatch(authActions.setIsFinanceManagerStore(true));
      }
      if (user?.roles?.some((role: any) => role.role.slug === ROLES_SLUG[0])) {
        setIsTradeManager(true);
        dispatch(authActions.setIsTradeManagerStore(true));
      }
    }
    const uRole = user?.roles[0] || '';
    if (uRole?.role?.slug === ROLES_SLUG[0]) {
      socket.on(`promotions/trade`, (data: any) => {
        // const msg = [];
        // msg.push(data.message);
        dispatch(getNotifications());
        // setNotificationData(prevState=>[data.message,...prevState]);
        setShowNotificationDot(true);
        dispatch(
          getListView({
            pageNumber: 1,
            pageSize: PAGE_SIZE_LIST_VIEW,
          }),
        );
        let today = new Date();
        dispatch(
          getCalendarView({
            // Adding 1 because getMonth() returns zero-based index
            monthNumber: today.getMonth() + 1,
            year: today.getFullYear(),
          }),
        );
        playAudio();
      });
    } else if (uRole?.role?.slug === ROLES_SLUG[1]) {
      socket.on(`promotions/finance`, (data: any) => {
        // const msg = [];
        // msg.push(data.message);
        // setNotificationData(prevState=>[data.message,...prevState]);
        dispatch(getNotifications());
        setShowNotificationDot(true);
        dispatch(
          getListView({
            pageNumber: 1,
            pageSize: PAGE_SIZE_LIST_VIEW,
          }),
        );
        let today = new Date();
        dispatch(
          getCalendarView({
            // Adding 1 because getMonth() returns zero-based index
            monthNumber: today.getMonth() + 1,
            year: today.getFullYear(),
          }),
        );
        playAudio();
      });
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      setIsAdmin(false);
      setIsFinanceManager(false);
      setIsTradeManager(false);
    };

    // return () => {
    //   socket.disconnect(); // Clean up when component unmounts
    // };
  }, []);

  const showNotificationDrawer = async () => {
    await dispatch(resetNotificationsCount());
    dispatch(getNotifications());
    setOpenNotification(true);
  };
  const onNotificationClose = () => {
    setOpenNotification(false);
    setShowNotificationDot(false);
    setTimeout(() => {
      setShowAllNotification(false);
    }, 500);
  };

  const userDropDownOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      //mixpanel?.track('User logout');
      //mixpanel?.identify(user.email);
      // mixpanel?.reset();
      localStorage.removeItem('viewTour');
      dispatch(authActions.signOut());
      history.push('/');
      dispatch(
        getPromotionsListSuccess({
          data: {
            promotions: [],
            pageInfo: {
              size: 10,
              page: 1,
              totalItems: 0,
              totalPages: 0,
            },
          },
        }),
      );
    }
  };
  const handleNotificationClick = (id: string) => {
    if (id) {
      history.push(`/promotion/edit/${id}`);
      setOpenNotification(false);
    }
  };

  const onFocusSearch = () => {
    setIsSearchVisible(true);
  };

  const onHandleSearch = (search: any) => {
    if (search === '') {
      dispatch(clearSearchAndGetRecent());
      return;
    } else {
      dispatch(getSearchResult(search));
    }
  };
  const handleSearchClick = (id: string, status: string) => {
    //if (!!id && (status === Status.Draft || status === Status.SendBack)) {
    //history.push(`/promotion/edit/${id}`);
    //setIsSearchVisible(false);
    //}
    if (isTradeManager && (status === 'sentback' || status === 'draft')) {
      history.push(`/promotion/edit/${id}`);
    } else {
      history.push(`/promotion/view/${id}`);
    }
  };

  const getDynamicDiff = (formattedDateTime: string) => {
    const now = dayjs();
    const diff = now.diff(formattedDateTime);

    if (diff < 1000) {
      return 'just now';
    } else if (diff < 60 * 1000) {
      return `${Math.floor(diff / 1000)} seconds ago`;
    } else if (diff < 60 * 60 * 1000) {
      return `${Math.floor(diff / (60 * 1000))} minutes ago`;
    } else if (diff < 24 * 60 * 60 * 1000) {
      return `${Math.floor(diff / (60 * 60 * 1000))} hours ago`;
    } else if (diff < 30 * 24 * 60 * 60 * 1000) {
      return `${Math.floor(diff / (24 * 60 * 60 * 1000))} days ago`;
    } else {
      return `${Math.floor(diff / (30 * 24 * 60 * 60 * 1000))} months ago`;
    }
  };

  useEffect(() => {
    let unreadNotiCount =
      notifications.filter((elem: INotification) => elem.unread).length || 0;

    setUnreadNotiCount(unreadNotiCount);
  }, [notifications]);

  return (
    <header className="px-3 py-1 bg-primary ut-header">
      {/* <audio ref={audioPlayer} src={NotificationSound} /> */}
      <Row align="middle" gutter={24} className="top" justify="space-between">
        <Col>
          <Row align="middle" className="nav">
            <Col>
              <Link to={'/'}>
                <img src={Logo} alt="Uptrade logo" className="logo"  height={34} width={26}/>
              </Link>
            </Col>
            <Col>
              <Button
                type="link"
                className="button--active hover:text-white"
                size="small"
              >
                TPM
              </Button>
              <Button
                type="link"
                className="!text-gray-light"
                size="small"
                disabled
              >
                TPA
              </Button>
              <Button
                type="link"
                className="!text-gray-light"
                size="small"
                disabled
              >
                TPO
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          {/* {isTradeManager && (
            <div className="ut-search-container" ref={searchContainerRef}>
              <Search
                placeholder="Search"
                className="ut-header-search"
                allowClear
                onSearch={onHandleSearch}
                onFocus={onFocusSearch}
                style={{ width: 310 }}
              />
              {isSearchVisible && (
                <div className="search-result-container">
                  <div className="px-4">
                    <div className="search-tabs">
                      <Button type="link" className="active">
                        TPM
                      </Button>
                      <Button type="link">TPA</Button>
                      <Button type="link">TPO</Button>
                    </div>
                  </div>
                  <div className="px-5">
                    <h4 className="heading">
                      {searchResult.length > 0
                        ? 'Search Results'
                        : 'Recently Searched'}
                    </h4>
                    <div className="text-center">
                      {loading && <Spin tip="Searching..." />}
                    </div>
                  </div>
                  <div className="even-container-scroll">
                    {!loading &&
                      recentSearch.length > 0 &&
                      searchResult.length < 1 &&
                      recentSearch?.map((item: any) => (
                        <SearchEvent
                          item={item}
                          handleSearchClick={handleSearchClick}
                        />
                      ))}

                    {!loading &&
                      searchResult.length > 0 &&
                      searchResult?.map((item: any) => (
                        <SearchEvent
                          item={item}
                          handleSearchClick={handleSearchClick}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          )} */}
        </Col>
        <Col>
          {user ? (
            <Row align="middle" className="mt-1">
              {isAdmin && (
                <Col className="mr-5">
                  <Link to={'/users'} type="link">
                    <SettingOutlined style={{ color: '#fff', fontSize: 18 }} />
                  </Link>
                </Col>
              )}

              <Col className="mr-5">
                {/* <SyncOutlined
                  // onClick={showNotificationDrawer}
                  style={{ fontSize: '18px', color: '#efe6e6' }}
                /> */}
              </Col>
              <Col>
                <Badge count={unreadNotiCountState} size="small">
                  <BellOutlined
                    onClick={showNotificationDrawer}
                    style={{ fontSize: '18px', color: '#efe6e6' }}
                  />
                </Badge>
              </Col>
              <Col>
                <Dropdown
                  menu={{ items: menu, onClick: userDropDownOnClick }}
                  trigger={['click']}
                  placement="bottomRight"
                  arrow
                  overlayClassName="ut-dropdown"
                >
                  <Button className="pp-username" type="text">
                    <Avatar src={user?.profileImage || ''}>
                      {user?.firstName
                        ? user?.firstName.charAt(0).toUpperCase()
                        : ''}
                    </Avatar>
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          ) : (
            <>
              <Button size="small" onClick={onLogin}>
                login
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <nav
            className="appbar-head"
            style={{
              display: 'inline-block',
            }}
          >
            {true && isTradeManager && (
              <Link to="/">
                <Button
                  type="link"
                  className={` ${
                    isRootUrl && 'link--active'
                  }  hover:text-white text-white text-sm`}
                >
                  Dashboard
                </Button>
              </Link>
            )}
            <Link to={!isTradeManager ? '/' : '/calendar'}>
              <Button
                type="link"
                className={`${
                  (isTradeManager
                    ? ['/promotion/list', '/calendar', '/'].includes(
                        currentUrl,
                      ) ||
                      currentUrl.includes('edit') ||
                      currentUrl.includes('view')
                    : ['/', '/promotion/list'].includes(currentUrl)) 
                  
                } text-white hover:text-white text-sm report-btn`}
              >
                Promotions
              </Button>
            </Link>
            {/* <Button type="link" className="text-white hover:text-white text-sm">
              Layout
            </Button> */}
            <Link to="/reporting">
              <Button
                type="link"
                className={`${
                  currentUrl === '/reporting' && 'link--active'
                } text-white hover:text-white text-sm report-btn`}
              >
                Reports
              </Button>
            </Link>
            <Button
              type="link"
              className="!text-gray-light hover:text-white text-sm"
              style={{
                cursor: 'not-allowed',
              }}
            >
              Products
            </Button>
            {isAdmin && (
            <Link to="/approval-hierarchy">

            <Button
              type="link"
              className="!text-gray-light hover:text-white text-sm"
              style={{
                cursor: 'not-allowed',
              }}
            >
              Approval Hierarchy
            </Button>
            </Link>
            )}
            {/* <Link to="/help">
              <Button
                type="link"
                className={`disable-btn ${
                  currentUrl === '/help' && 'link--active'
                } text-white hover:text-white text-sm`}
                disabled
                style={{
                  color: '#ccc !important',
                }}
              >
                Help
              </Button>
            </Link> */}
          </nav>
        </Col>
      </Row>
      <Drawer
        className={`ut-drawer ut-notification-drawer ${
          !showAllNotification && notifications.length > 2
            ? 'show-first-notification'
            : ''
        }`}
        title="Notification"
        placement="right"
        open={openNotification}
        onClose={onNotificationClose}
        maskStyle={{ background: 'none' }}
        contentWrapperStyle={{ boxShadow: 'none' }}
      >
        {notifications.length ? (
          <div className="ut-notification-list-container">
            <List
              dataSource={notifications}
              className="ut-notification-list"
              renderItem={(item, index) => (
                <List.Item
                  key={item?.id}
                  className={`ut-notification-li cursor-pointer ut-border-${
                    item?.otherData?.status
                  } ${index % 2 == 0 ? 'ut-bg-notification' : ''}`}
                  onClick={() => {
                    // if (
                    //   item?.otherData?.status === 'sendback' ||
                    //   item?.otherData?.status === 'sentback' ||
                    //   item?.otherData?.status === 'draft'
                    // ) {
                    //   return;
                    // } else {
                    handleNotificationClick(item?.otherData?.promoId);
                    // }
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item?.fromUser?.profileImage}>
                        {item?.fromUser?.firstName
                          ? item.fromUser.firstName.charAt(0).toUpperCase()
                          : ''}
                      </Avatar>
                    }
                    title={
                      <div className="flex justify-between">
                        <Button type="link">
                          {item?.otherData?.promoCode}
                        </Button>
                        <div className="notification-date">
                          {getDynamicDiff(item?.createdAt)}
                        </div>
                      </div>
                    }
                    description={
                      <span
                        dangerouslySetInnerHTML={addStrongTags(
                          item?.text || '',
                        )}
                      />
                    }
                  />
                </List.Item>
              )}
            >
              {loading && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </List>
          </div>
        ) : (
          <div className="no-notification">
            {notifications.length === 0 && (
              <span>No notification to show...</span>
            )}
          </div>
        )}
        {!showAllNotification && notifications.length > 2 && (
          <div className="ut-view-all-container ut-bg-notification">
            <button
              type="button"
              className="ut-btn-view-all"
              onClick={() => setShowAllNotification(true)}
            >
              View All
            </button>
          </div>
        )}
      </Drawer>
    </header>
  );
};
